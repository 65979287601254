<template>
  <div class="pb-[30px]">
      <div class="flex flex-row justify-between items-center">
        <div v-if="viewType === 'table'" class="pr-5 text-xs flex w-3/5 gap-x-4 pb-3">
            <!-- <v-text-field placeholder="Customer Name" variant="outlined" density="compact" @keyup.enter="searchFilter" v-model="name" rounded="0">
            </v-text-field>
            <v-text-field placeholder="Customer Email" variant="outlined" density="compact" @keyup.enter="searchFilter" v-model="email" rounded="0">
            </v-text-field>
            <v-text-field placeholder="Type" variant="outlined" density="compact" @keyup.enter="searchFilter" v-model="type" rounded="0">
            </v-text-field>
            <v-text-field placeholder="Status" variant="outlined" density="compact" @keyup.enter="searchFilter" v-model="status" rounded="0">
            </v-text-field>
            <v-btn icon="mdi-magnify" size="small" style="outline: none;" @click="searchFilter" class="bg-dark text-light"></v-btn> -->
        </div>
      </div>
      <div class="flex flex-col px-[100px] text-[16px] pb-[30px] gap-y-4">
        <p>Welcome to our Referral Program!</p>
        <p>We're excited to offer you the opportunity to earn rewards by referring new customers to our proxy services. When your referrals sign up and make a purchase, you'll receive a {{commission}}% commission on their order total, credited directly to your account.</p>
        <p>Here's how it works:</p>
        <div class="pl-[20px] flex flex-col gap-y-4">
          <p>1.  Share your unique referral link with your friends, family, and colleagues.</p>
          <p>2.  When someone clicks your link and signs up for a new account, they'll be marked as your referral.</p>
          <p>3.  When your referral makes a purchase of any of our proxy plans, you'll receive a {{commission}}% commission on their order total.</p>
          <p>4.  Your commissions will be credited directly to your account, and you can use them to purchase any of our proxy plans or contact us over Live Chat and we can make a manual payout via USDT.</p>
        </div>
        <p>There are no limits to the number of referrals you can make, so the more you share, the more you can earn! Plus, there's no need to worry about privacy - our referral program is fully anonymous and complies with all relevant privacy regulations.</p>
        <p>So what are you waiting for? Start sharing your referral link today and start earning rewards!</p>
        <p>Note: Referral commissions are only eligible for new customers who sign up and make a purchase through your unique referral link. Commissions will be credited to your account the same moment of the referral's purchase</p>
        <p>
          Your referral link:
        </p>
        <div class="ml-4 border p-3 w-fit">
          {{referralLink}}
          <v-btn density="compact" variant="text" icon="mdi-pencil-box-multiple-outline"
            @click="referralCopy"
            color="light-green">
            <v-icon icon="mdi-pencil-box-multiple-outline" size="22"></v-icon>
          </v-btn>
        </div>
      </div>
      <TableView :filterName="filterName" :filterEmail="filterEmail" :filterStatus="filterStatus" :filterType="filterType" />
  </div>
</template>
<script setup>
import { ref, watchEffect } from 'vue';
import TableView from "./TableView.vue"
import copy from "clipboard-copy"
import {useAccountStore, useAlertStore} from "@/stores"
import {storeToRefs} from "pinia"
const accountStore = useAccountStore()
const {user, commission} = storeToRefs(accountStore)
accountStore.getCommission();
const name = ref("");
const email = ref('');
const type = ref("");
const status = ref("");
const viewType = ref("table");
const referralLink = ref('');
const nowDate = new Date();
const oneMonthAgoDate = new Date(nowDate);
oneMonthAgoDate.setMonth(nowDate.getMonth() - 1);

let filterName = '';
let filterEmail = '';
let filterType = '';
let filterStatus = '';

const searchFilter = () => {
filterEmail = email.value;
filterName = name.value;
filterStatus = status.value;
filterType = type.value;
}

watchEffect(() => {
  referralLink.value =  `${process.env.VUE_APP_VUE_BASEURL}/register?r=` + user.value.referral_code;
})

const referralCopy = () => {
    copyText(`${process.env.VUE_APP_VUE_BASEURL}/register?r=` + user.value.referral_code);
}
const copyText = (text) => {
  copy(text)
  const alertStore = useAlertStore()
  alertStore.success("Referral Link Copied");
}

const getBaseUrl = (urlString) => {
  try {
    const url = new URL(urlString);
    return `${url.protocol}//${url.host}`;
  } catch (error) {
    console.error("Invalid URL:", error);
    return null; // Handle invalid URLs if necessary
  }
}

</script>