
<template>
  <BreadCrumb PageTitle="Profile" />
  <div class="row">
    <div class="col-xxl-12">
      <ProfileContent />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "@/components/Common/BreadCrumb.vue";
import ProfileContent from "@/components/Customer/Account/AccountContent.vue";

export default defineComponent({
  name: "ProfilePage",
  components: {
    BreadCrumb,
    // WhatHappening,
    ProfileContent,
    // ToDoList,
    // RecentActivity,
    // WorkingSchedule,
  },
});
</script>