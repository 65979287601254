<template>
    <div class="modal fade" id="licenseDetailModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  w-[500px] ">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">License Details</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body text-[16px]">
                    <div class="overflow-hidden">
                        <div class="float-left w-1/2 p-2 h-20 sm:w-1/3">
                            Total Users :
                            <div>
                                {{totalUsers}}
                            </div>
                        </div>
                        <div class="float-left w-1/2 p-2 h-20 sm:w-1/3">
                            Active Users :
                            <div>
                                {{activeUsers}}
                            </div>
                        </div>
                        <div class="float-left w-1/2 p-2 h-20 sm:w-1/3">
                            Proxy Servers :
                            <div>
                                {{totalModems}}
                            </div>
                        </div>
                        <div class="float-left w-1/2 p-2 h-20 sm:w-1/3">
                            Total Users :
                            <div>
                                {{totalProxys}}
                            </div>
                        </div>
                        <div class="float-left w-1/2 p-2 h-20 sm:w-1/3">
                            Available Proxy :
                            <div>
                                {{availableProxys}}
                            </div>
                        </div>
                        <div class="float-left w-1/2 p-2 h-20 sm:w-1/3">
                            Total License :
                            <div>
                                {{totalLicenses}}
                            </div>
                        </div>
                        <div class="float-left w-1/2 p-2 h-20 sm:w-1/3">
                            Expiring Today :
                            <div>
                                {{expiringLicenses}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>



<script setup>
import { useAdminStatisticStore } from '@/stores';
import { storeToRefs } from 'pinia';

// const accountStore = useAccountStore()
const adminStaticStore = useAdminStatisticStore()
adminStaticStore.loadStatisticsInfo()
// const { user } = storeToRefs(accountStore)
const { totalUsers, activeUsers, totalModems, totalProxys, availableProxys, totalLicenses, expiringLicenses } = storeToRefs(adminStaticStore)




</script>
