import { io } from 'socket.io-client';
import { updateAccount } from './account.store';
import { success } from './alert.store';
import { updateTransactionHistory } from './payment.store';

const socket = io(`${process.env.VUE_APP_VUE_SOCKET_URL}`);
const connectToWebSocket = (username) => {
    socket.emit('user-connect', { username });
}

const disConnectToWebSocket = (username) => {
    socket.emit('user-disconnect', { username });
}

socket.on('message', (message) => {
    success(message)
    // updateAccount("status","true")
    updateTransactionHistory()
});

export { connectToWebSocket, disConnectToWebSocket }