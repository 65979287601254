import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '..';
import { updateLicenses } from '../account.store';
import { updateStatistics } from './statistics.store';

const baseUrl = `${process.env.VUE_APP_VUE_API_URL}`;

export const useAdminLicenseStore = defineStore('admin-license', {
    state: () => ({
        Licenses:[],
        customers:[],
        loading: false
    }),
    actions: {
        async loadLicenses(data) {
            const alertStore = useAlertStore();
            try {
                this.loading  = true
                const res = await fetchWrapper.post(`${baseUrl}/admin/license/load`, data);
                if(data.from === 0 || !data.from)
                    this.Licenses = res.totalLicenses
                else this.Licenses.push(...res.totalLicenses);
                updateStatistics()
                this.loading = false
                } catch (error) {
                    this.loading = false;
                    alertStore.error(error);
                }
        },
        async updateLicense(updateRequest) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/license/update`, { updateRequest });
                alertStore.success("Update successful")

            } catch (error) {
                alertStore.error(error);
            }
        },
        async changeIp(uuid) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/license/change-ip`, { license:uuid });
                alertStore.success("Changed IP")

            } catch (error) {
                alertStore.error(error);
            }
        },
        async reboot(uuid) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/license/reboot`, { license:uuid });
                alertStore.success("Reboot Proxy successed")

            } catch (error) {
                alertStore.error(error);
            }
        },
        async getModemList(license_uuid) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/admin/license/get-modem-list`, { license_uuid });
                return res.modemList
            } catch (error) {
                alertStore.error(error);
            }
        },
        async getProxiesAndPackages(serverName) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/admin/license/get-proxy-package-list`, { serverName });
                return {proList: res.proxyList, pkgList:res.packageList}
            } catch (error) {
                alertStore.error(error);
                return {proList: [], pkgList: []}
            }
        },
        async changeModem(updateRequest) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/license/change-modem`, { updateRequest });
                alertStore.success("Changed Modem")
                return
            } catch (error) {
                alertStore.error(error);
            }
        },
        async createLicense(createRequest) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/license/create`, { createRequest });
                updateLicenses()
                alertStore.success("Created new License")
                return
            } catch (error) {
                alertStore.error(error);
            }
        },
        async getClientList() {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.get(`${baseUrl}/admin/license/get-clients`);
                return res.userList
            } catch (error) {
                alertStore.error(error);
            }
        },
        async deleteLicenses(uuids) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/license/delete`, { uuids });
                updateLicenses()
                alertStore.success("Delete successful")
            } catch (error) {
                alertStore.error(error);
            }
        },
        async getCustomers() {
            const alertStore = useAlertStore();
            try{
                const res = await fetchWrapper.get(`${baseUrl}/admin/user/get-customer-names`);
                this.customers = res.users
            } catch (error) {
                alertStore.error(error);
            }
        }
    },
});