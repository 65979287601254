<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing lead-info-box" v-if="user">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <div class="profile-info d-sm-flex align-items-center">
        <img src="../../../assets/images/default-user-avatar.png" class="rounded" alt="user" />
        <div class="title mt-12 mt-sm-0">
          <h5 class="text-black fw-medium mb-8">{{user.name}}</h5>
          <span class="d-block fs-md-15 fs-lg-16 text-dark-emphasis mb-8">
            {{user.role}}
          </span>
        </div>
      </div>
      <div class="border-top mt-15 mb-15 mt-sm-20 mb-sm-20 mt-md-25 mb-md-25 mt-lg-30 mb-lg-30"></div>
      <div class="d-flex align-items-center justify-content-between">
        <h5 class="fw-medium text-black-emphasis mb-0">Personal Information</h5>
        <router-link to="/account-setting" class="d-inline-block text-decoration-none lh-1 text-muted fw-medium">
          <i class="ph-duotone ph-pencil text-black position-relative top-1 fs-16 me-1"></i>
          Edit
        </router-link>
      </div>
      <ul class="info mt-25 ps-0 mb-0 list-unstyled">
        <li class="position-relative">
          <div class="icon text-success rounded-circle text-center">
            <i class="flaticon-mail-inbox-app"></i>
          </div>
          <span class="d-block text-black mb-5 fw-semibold">Email</span>
          <a :href="`mailto:${user.email}`"
            class="d-inline-block fs-md-15 fs-lg-16 text-primary text-decoration-none">
            {{user.email}}
          </a>
        </li>
        
        <li class="position-relative">
          <div class="icon text-primary rounded-circle text-center">
            <i class="flaticon-web"></i>
          </div>
          <span class="d-block text-black mb-5 fw-semibold">Address</span>
          <a href="#" target="_blank" class="d-inline-block fs-md-15 fs-lg-16 text-muted text-decoration-none">
            {{user.address}}, {{ user.city }}, {{ user.country }}
          </a>
        </li>
        <li class="position-relative">
          <div class="icon text-success rounded-circle text-center">
            <i class="flaticon-money"></i>
          </div>
          <span class="d-block text-black mb-5 fw-semibold">
            Account Balance
          </span>
          <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
            $ {{user.balance}} USD
          </span>
        </li>
        <li v-if="user.role === 'user'" class="position-relative">
          <div class="icon text-success rounded-circle text-center flex items-center justify-center">
            <img src="../../../assets/tg.png" class="w-[30px]" />
          </div>
          <span class="d-block text-black mb-5 fw-semibold">
            Telegram
          </span>
          <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
            {{formatTgLink(user.tglink)}}
          </span>
        </li>
      </ul>
      <div v-if="user.role === 'admin'" class="d-flex align-items-center justify-content-between pt-4">
        <h5 class="fw-medium text-black-emphasis mb-0">Cryptomus Information</h5>
      </div>
      <ul v-if="user.role === 'admin'" class="info mt-25 ps-0 mb-0 list-unstyled">
        <li class="position-relative">
          <div class="icon text-success rounded-circle text-center flex items-center justify-center">
            <img src="../../../assets/merchant.png" class="w-[30px]" />
          </div>
          <span class="d-block text-black mb-5 fw-semibold">
            Cryptomus Merchant ID
          </span>
          <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
            {{user?.mid}}
          </span>
        </li>
        <li class="position-relative">
          <div class="icon text-success rounded-circle text-center flex items-center justify-center">
            <img src="../../../assets/key_icon.png" class="w-[35px]" />
          </div>
          <span class="d-block text-black mb-5 fw-semibold ">
            Cryptomus Payment API Key
          </span>
          <span class="d-inline-block fs-md-15 fs-lg-16 text-muted w-full line-clamp-1">
            {{user?.apikey}}
          </span>
        </li>
      </ul>
      <div v-if="user.role === 'admin'" class="d-flex align-items-center justify-content-between pt-4">
        <h5 class="fw-medium text-black-emphasis mb-0">SMTP Information</h5>
      </div>
      <ul v-if="user.role === 'admin'" class="info mt-25 ps-0 mb-0 list-unstyled">
        <li class="position-relative">
          <div class="icon text-success rounded-circle text-center flex items-center justify-center">
            <img src="../../../assets/smtp.png" class="w-[30px]" />
          </div>
          <span class="d-block text-black mb-5 fw-semibold">
            SMTP Sender Email
          </span>
          <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
            {{user?.smtpsender}}
          </span>
        </li>
        <li class="position-relative">
          <div class="icon text-success rounded-circle text-center flex items-center justify-center">
            <img src="../../../assets/username.png" class="w-[35px]" />
          </div>
          <span class="d-block text-black mb-5 fw-semibold">
            SMTP Sender Username
          </span>
          <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
            {{user?.smtpuser}}
          </span>
        </li>
        <li class="position-relative">
          <div class="icon text-success rounded-circle text-center flex items-center justify-center">
            <img src="../../../assets/host.png" class="w-[30px]" />
          </div>
          <span class="d-block text-black mb-5 fw-semibold">
            SMTP Server Host
          </span>
          <span class="d-inline-block fs-md-15 fs-lg-16 text-muted">
            {{user?.smtphost}}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import { useAccountStore } from "@/stores"
import { storeToRefs } from "pinia"

export default {
  name: "ProfileContent",
  setup() {
    // const isSticky = ref(false);

    const accountStore = useAccountStore()
    const { user } = storeToRefs(accountStore)
    const formatTgLink = (tglink) => {
      if(tglink) {
        const cleanedID = tglink.replace(/(@|https?:\/\/t\.me\/)/g, "").trim();
        const telegramUrl = `https://t.me/${cleanedID}`;
        return telegramUrl;
      } else return ''
    }
    return {
      user, formatTgLink
    };
  }

};
</script>