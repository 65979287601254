<template>
  <div class="modal fade" id="couponModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered  w-[400px] ">
          <div class="modal-content">
              <Form @submit="onUpdate" >

                  <div class="modal-header">
                      <h1 class="modal-title fs-5">Add or Update Coupon</h1>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body" v-if="props.license">
                      <div class="text-sm  pt-4 space-y-2">
                          <Field name="coupon" >
                              <div class="relative">
                                  <input v-model="coupon"
                                      class=" form-control shadow-none rounded-0 text-black" />
                              </div>
                          </Field>
                      </div>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                      <button type="submit" :disabled="buttonDisableFlag" class="btn btn-primary">Update</button>
                  </div>
              </Form>
          </div>
      </div>
  </div>
</template>
<script setup>
import { ref, watch, defineProps, defineEmits, watchEffect } from 'vue';
import { Form, Field } from 'vee-validate';

import {useProxyStore} from "@/stores"
const proxyStore = useProxyStore()
const buttonDisableFlag = ref(false)
const props = defineProps({
  license: {
      type: Object, // Assuming user object has a name property
      required: true
  },
});
const coupon = ref('')
watch(() => props.license, (newvalue) => {
  if(newvalue) {
    coupon.value = newvalue.coupon_code
  }
})
const onUpdate = ( (values) => {
  // const {note} = values;
  buttonDisableFlag.value = true;
  proxyStore.updateCoupon(props.license.uuid, coupon.value).then(res=>{
      buttonDisableFlag.value = false
      emit('close');
  })
});
const emit = defineEmits(['inInput', 'submit'])
</script>