import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '..';
import { updateStatistics } from './statistics.store';

const baseUrl = `${process.env.VUE_APP_VUE_API_URL}`;

export const useAdminServerStore = defineStore('admin-server', {
    state: () => ({
        servers:[],
        charts:{
            count:[],
            amount:[]
        }
    }),
    actions: {
        async loadServers() {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.get(`${baseUrl}/admin/load-servers`,);
                this.servers = res.totalModems
                updateStatistics()
            } catch (error) {
                alertStore.error(error);
            }
        },
        async createServer(createOption) {
            const {modemName, domain, country, city, isp, username, password, modemType} = createOption;
            
            const alertStore = useAlertStore();
            try{
                const res = await fetchWrapper.post(`${baseUrl}/admin/server/create-server`,{modemName, domain, country, city, isp, username, password, modemType});
                this.servers = [...this.servers,res.createdModem]
                alertStore.success("New successfully added")

            }
            catch (error){
                alertStore.error(error);
            }
        },
        async updateServer(updateOption) {
            const {uuid, modemName, domain, country, city, isp, username, password, modemType} = updateOption;
            const alertStore = useAlertStore();
            try{
                const res = await fetchWrapper.post(`${baseUrl}/admin/server/update-server`,{uuid, modemName, domain, country, city, isp, username, password, modemType});
                this.servers = this.servers.map(server=>{
                    if(server.uuid===uuid){
                        return res.updatedData
                    }
                    else{
                        return server
                    }
                })
                alertStore.success("Update successful")

            }
            catch (error){
                alertStore.error(error);
            }
        },
        async syncServer(uuid) {
            const alertStore = useAlertStore();
            try{
                await fetchWrapper.post(`${baseUrl}/admin/server/sync-server`,{uuid});
                alertStore.success("Sync successful")
                
            }
            catch (error){
                alertStore.error(error);
            }
            
            updateStatistics()
        },
        async pauseOrResume(uuid) {
            const alertStore = useAlertStore();
            try{
                const res = await fetchWrapper.post(`${baseUrl}/admin/server/pause-server`,{uuid});
                this.servers = this.servers.map(server=>{
                    if(server.uuid===uuid){
                        return res.updatedData
                    }
                    else{
                        return server
                    }
                })
                
            }
            catch (error){
                alertStore.error(error);
            }
            
            updateStatistics()
        },
        async deleteServer(uuid) {
            const alertStore = useAlertStore();
            try{
                const res = await fetchWrapper.post(`${baseUrl}/admin/server/delete-server`,{uuid});
                if(res===true)
                this.servers = this.servers.filter(server=>server.uuid!==uuid)
                alertStore.success("Delete successful")

            }
            catch (error){
                alertStore.error(error);
            }
        },
        async advanceSearch(searchOption) {
            const {modemName, domain, location, isp, username, password, modemType} = searchOption;
            const alertStore = useAlertStore();
            try{
                const res = await fetchWrapper.post(`${baseUrl}/admin/server/advance-search`,{modemName, domain, location, isp, username, password, modemType});
                this.servers = [...this.servers,res.createdModem]
            }
            catch (error){
                alertStore.error(error);
            }
        },
        async fetchCharts(filters) {
            const {searchFilterName, searchFilterLocation, searchFilterDomain} = filters
            const alertStore = useAlertStore();
            try{
                const res = await fetchWrapper.post(`${baseUrl}/admin/server/fetch-charts`,{searchFilterName, searchFilterLocation, searchFilterDomain});
                this.charts = {
                    amount : res.amounts,
                    count : res.counts
                }
            }
            catch (error){
                alertStore.error(error);
            }
        },
        async getCountryList() {
            const alertStore = useAlertStore();
            try{
                const res = await fetchWrapper.post(`${baseUrl}/admin/server/get-country-list`,{});
                return res.countryList
            }
            catch (error){
                alertStore.error(error);
            }
        },
        async getISPLIst(countryName) {
            const alertStore = useAlertStore();
            try{
                const res = await fetchWrapper.post(`${baseUrl}/admin/server/get-isp-list`,{countryName});
                return res.ispList
            }
            catch (error){
                alertStore.error(error);
            }
        }
    },
});