<template>
    <div class="space-x-2 w-full hidden lg:block">
        <span class="badge text-bg-primary  rounded-pill fs-13 m-1">Total Users : {{totalUsers - 1}}</span>
        <span class="badge text-bg-primary  rounded-pill fs-13 m-1">Active Users : {{activeUsers}}</span>
        <span class="badge text-bg-primary  rounded-pill fs-13 m-1">Proxy Servers : {{totalModems}}</span>
        <span class="badge text-bg-primary  rounded-pill fs-13 m-1">Total Proxy : {{totalProxys}}</span>
        <span class="badge text-bg-primary  rounded-pill fs-13 m-1">Available Proxy : {{availableProxys}}</span>
        <span class="badge text-bg-primary  rounded-pill fs-13 m-1">Total License : {{totalLicenses}}</span>
        <span class="badge text-bg-primary  rounded-pill fs-13 m-1">Expiring Today : {{expiringLicenses}}</span>
    </div>
</template>


<script setup>

import { useAdminStatisticStore } from '@/stores';
import { storeToRefs } from 'pinia';

// const accountStore = useAccountStore()
const adminStaticStore = useAdminStatisticStore()
adminStaticStore.loadStatisticsInfo()
// const { user } = storeToRefs(accountStore)
const { totalUsers, activeUsers, totalModems, totalProxys, availableProxys, totalLicenses, expiringLicenses } = storeToRefs(adminStaticStore)

// const loadStatistics = ()=>{
//     adminStaticStore.loadStatisticsInfo()
// }
// updateAccount("status", "true")
// updateLicenses()
</script>