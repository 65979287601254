<template>
  
  <BuyNewProxy />
</template>

<script>
import { defineComponent } from "vue";
import BuyNewProxy  from "@/components/Customer/BuyNewProxy/BuyNewProxy.vue";

export default defineComponent({
  name: "BuyNewProxyPage",
  components: {
    BuyNewProxy,
  },
});
</script>