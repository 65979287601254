import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '..';

const baseUrl = `${process.env.VUE_APP_VUE_API_URL}`;

export const useAdminTransactionStore = defineStore('admin-transaction', {
    state: () => ({
        Transactions:[],
        isLoading: false,
        totalCount: 0,
        loading: false
    }),
    actions: {
        async loadTransactions(data) {
            const alertStore = useAlertStore();
            try {
                this.loading = true;
                const res = await fetchWrapper.post(`${baseUrl}/admin/load-transactions`, data);
                if(data.from === 0 || !data.from)
                    this.Transactions = res.totalTransactions
                else this.Transactions.push(...res.totalTransactions);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                alertStore.error(error);
            }
        },
        async loadCount(data) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.get(`${baseUrl}/admin/load-count`, data);
                this.totalCount = res.count
            } catch (error) {
                alertStore.error(error);
            }
        },
    },
});