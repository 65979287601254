<template>
  <div>
    <button class="transition position-relative lh-1 bg-transparent p-0 border-0 text-black mb-4 mx-2 hidden listshow"
      id="userlist-show" @click="stateUserList">
      <i class="fa fa-bars text-[20px]"></i>
    </button>
  </div>
  <div class="admin-chat-container">
    <UserList :users="sortedCustomers" @selectUser="selectUser" />
    <div class=" w-full flex flex-col justify-center items-center" :style="`height: ${computedHeight}px;`">
      <div ref="chatContent" class="chatContent flex flex-col overflow-y-auto w-full h-full mb-5" >
        <div v-for="message in messageList" class="message" :key="message.id">
          <div v-if="message.from === user.id" class=" container flex-col flex w-full text py-2 px-4 darker">
            <p>{{ message.text }}</p>
            <span class="time-left">{{ formatDateTime(message.timestamp) }}</span>
            </div>
          <div v-else class=" container flex w-full justify-start flex-col text py-2 px-4">
            <p>{{ message.text }}</p>
            <span class="time-right">{{ formatDateTime(message.timestamp) }}</span>
          </div>
        </div>
      </div>
      <div class="w-2/3 mt-4 flex flex-row items-center gap-x-3">
        <v-text-field ref="input" v-model="messageInput" class="w-full" variant="outlined" density="compact" placeholder="Type your message here" @keyup.enter="send" 
        rounded="1" style=""></v-text-field>
        <v-icon icon="mdi-send" color="blue" size="35px" @click="send"></v-icon>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, watch, ref, onMounted, onBeforeUnmount } from 'vue';
import UserList from './UserList.vue';
import { useAccountStore, useAdminUserStore, useAlertStore } from "@/stores";
import {useChat} from "@/firebase.js";
const {messages, sendMessage, setUserId, markMessagesAsReadAdmin, loadMoreMessages, unreadCounts} = useChat()
const adminUserStore = useAdminUserStore()
const alertStore = useAlertStore();
const { Users } = storeToRefs(adminUserStore)
adminUserStore.loadUsers()
const messageInput = ref('')
import { storeToRefs } from "pinia";
const accountStoroe = useAccountStore();
const {user} = storeToRefs(accountStoroe);
const selectedUser = ref(null);
const chatContent = ref(null);
const input = ref(null)
const stateUserList = () => {
  const userlist = document.getElementById('userlist')
  if (userlist.classList.contains('userhidden')) {
    // Show the user list (left to right)
    userlist.classList.remove('userhidden');
    userlist.classList.add('uservisible');
  } else {
    // Hide the user list (right to left)
    userlist.classList.remove('uservisible');
    userlist.classList.add('userhidden');
  }
}
const messageList = computed(() => {
  return [...messages.value].reverse();
});
// const a = computed(() => {
//   let count = 0;
//   unreadCounts.value.map(item => {
//     count += item.count;
//   })
//   accountStoroe.setUnread(count)
//   return count;
// })
const selectUser = (selecteduser) => {
  setUserId(selecteduser.user_id, true)
  markMessagesAsReadAdmin()
  selectedUser.value = selecteduser;
  // chatStore.messageRead(selecteduser.id, user.value.id);
};


const send = () => {
  if(messageInput.value === '' || !selectedUser.value?.user_id) 
  {
    alertStore.error("Please select user or enter message");
    return;
  }
  sendMessage(messageInput.value, user.value.id, selectedUser.value.user_id)
  messageInput.value = ''
  nextTick(() => {
    input.value.focus()
    chatContent.value.scrollTop = chatContent.value.scrollHeight;
  })
}

const offsetHeight = 250; // Adjust this value as needed

const computedHeight = ref(window.innerHeight - offsetHeight);

const handleResize = () => {
  computedHeight.value = window.innerHeight - offsetHeight;
};
onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
let loadingMoreMessages = false;

const handleScroll = () => {
  if (chatContent.value.scrollTop === 0 && !loadingMoreMessages) {
    const previous = chatContent.value.scrollHeight
    loadingMoreMessages = true;
    loadMoreMessages().then(() => {
      nextTick(() => {
        loadingMoreMessages = false;
        chatContent.value.scrollTop = chatContent.value.scrollHeight - previous;
      });
    });
  }
};

const sortedCustomers = computed(() => {
  return Users.value.map(customer => {
    const unread = unreadCounts.value.find(unread => unread.id === customer.user_id);
    try{
    return {
      ...customer,
      unreadCount: unread ? unread?.count : 0,
      lastMessageTime: unread ? unread?.time?.toDate() : null
    };
    } catch(error) {
      return {
      ...customer,
      unreadCount: unread ? unread?.count : 0,
      lastMessageTime: null
    };
    }
  }).sort((a, b) => {
    if (!a.lastMessageTime) return 1;
    if (!b.lastMessageTime) return -1;
    return b.lastMessageTime - a.lastMessageTime;
  });
});
const formatDateTime = (timestamp) => {
  try {
  const date = timestamp?.toDate();
  if(date){
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}.${month}.${day}  ${hours}:${minutes}`;
  } else return ''
  } catch(error) {
    return ''
  }
};

watch(messages, () => {
  nextTick(() => {
    if (!loadingMoreMessages) {
      // chatContent.value.scrollIntoView({behavior: 'smooth'})
      chatContent.value.scrollTop = chatContent.value.scrollHeight;
      markMessagesAsReadAdmin()
    }
  })
}, {deep: true})
</script>

<style scoped>
.admin-chat-container {
  display: flex;
  height: 100%;
}
.container {
  border: 2px solid #dedede;
  background-color: #afafaf;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 10px;
  margin: 10px 0;
  margin: 10px;
  max-width: 400px;
  width: fit-content;
}
.container::after {
  content: "";
  clear: both;
  display: table;
}
.darker {
  border-color: #ccc;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 30px;
  background-color: #6560f0;
  float: right;
}

.time-right {
  display: flex;
  justify-content: end;
  color: #696969;
}

/* Style time text */
.time-left {
  float: left;
  color: #fdd2d2;
}

@media only screen and (max-width: 500px) {
  .listshow {
    display: block; 
  }
}
</style>
