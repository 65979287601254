<template>
    <div v-if="user" class="">
        <div class="flex w-[90%] mx-auto space-x-2 configuration md:flex-row flex-col ">
            <div class=" md:w-1/2 md:pr-2 w-full p-2">
                <p class="mb-3">
                    Country
                </p>
                <v-card class="border-t bg-white text-black">
                    <v-data-table-virtual :headers="countryHeaders" :items="countryList" :hover="true" class="bg-white text-black">
                        <template v-slot:[`item.no`]="{ index, item }">
                            <div class="w-full h-full flex items-center justify-center cursor-pointer"
                                @click="selectedCountry(item)" :class="item.selected ? 'bg-gray-200' : ''">
                                {{ index + 1 }}
                            </div>
                        </template>
                        <template v-slot:[`item.name`]="{ item }">
                            <div class="w-full h-full flex items-center justify-center text-center cursor-pointer"
                                @click="selectedCountry(item)" :class="item.selected ? 'bg-gray-200' : ''">
                                {{ item.name }}
                            </div>
                        </template>
                        <template v-slot:[`item.flag`]="{ item }">
                            <div class="w-full h-full flex items-center justify-center text-center cursor-pointer"
                                @click="selectedCountry(item)" :class="item.selected ? 'bg-gray-200' : ''">
                                <v-img :src="item.iconurl"></v-img>
                            </div>
                        </template>
                        <template v-slot:[`item.sellable`]="{ item }">
                            <div class="w-full h-full flex justify-center items-center text-center cursor-pointer"
                                :class="item.selected ? 'bg-gray-200' : ''">
                                <v-switch v-model="item.sellable" color="success" @click="setCountrySellable(item)"></v-switch>
                            </div>
                        </template>
                        <template v-slot:[`item.order`]="{ item }">
                            <div class="w-full h-full flex items-center justify-center cursor-pointer"
                                @click="setCountryOrder(item)" :class="item.selected ? 'bg-gray-200' : ''">
                                {{ item.order ? item.order : 0 }}
                            </div>
                        </template>
                    </v-data-table-virtual>
                    
                </v-card>
                <div class=" mt-4 text-[20px] flex items-center" >
                    <span class=" mr-4 ">Mixing Proxysmart and X-Proxy:</span> 
                    <v-switch v-model="openvpn" color="success" @click="setOpenvpn()"></v-switch>
                </div>
            </div>
            <div class="md:w-1/2 md:pr-2 w-full p-2">
                <p class="mb-3">
                    ISP
                </p>
                <v-card class="border-t bg-white text-black">
                    <v-data-table-virtual :headers="ispHeaders" :items="filteredISP" class="bg-white text-black">
                        <template v-slot:[`item.no`]="{ index }">
                            <div class="w-full h-full flex items-center justify-center cursor-pointer">
                                {{ index + 1 }}
                            </div>
                        </template>
                        <template v-slot:[`item.isp_image`]="{ item }">
                            <div class="w-full ">
                                <v-file-input multiple label="Choose an image" accept="image/*"
                                    @change="(event) => onFileChange(event, item)" :ref="`fileInput_${item.id}`"
                                    :name="`fileInput_${item.id}`" style="display: none">
                                </v-file-input>
                                <v-img :src="item.isp_image" class="w-[100px]  aspect-square" cover
                                    style="margin-left: auto; margin-right: auto;" @click="openFileInput(index, item)">
                                </v-img>
                            </div>
                        </template>
                        <template v-slot:[`item.sellable`]="{ item }">
                            <div class="w-full h-full flex justify-center items-center text-center cursor-pointer"
                                :class="item.selected ? 'bg-gray-200' : ''">
                                <v-switch v-model="item.sellable" color="success" @click="setIspSellable(item)"></v-switch>
                            </div>
                        </template>
                    </v-data-table-virtual>
                </v-card>
            </div>
        </div>
    </div>
</template>
<script setup>

import { storeToRefs } from 'pinia';
import { useAdminConfigurationStore, useAccountStore } from "@/stores"
import { ref, watchEffect } from 'vue';
const adminConfigurationStore = useAdminConfigurationStore();
const accountStore = useAccountStore()
const { user } = storeToRefs(accountStore);
const backendUrl = process.env.VUE_APP_VUE_SOCKET_URL
const initialISP = ref([])
const filteredISP = ref([])
let currentOrderValue = 0
const countryHeaders = ref([
    { title: 'No', align: 'center', key: 'no' },
    { title: 'Country Name', align: 'center', key: 'name' },
    { title: 'Flag', align: 'center', key: 'flag' },
    { title: 'Sellable', align: 'center', key: 'sellable' },
    { title: 'Order', align: 'center', key: 'order' }
]);
const ispHeaders = ref([
    { title: 'No', align: 'center', key: 'no' },
    { title: 'ISP Name', align: 'center', key: 'name' },
    { title: 'Image', align: 'center', key: 'isp_image' },
    { title: 'Sellable', align: 'center', key: 'sellable' }
]);

const { countryList, ispList, openvpn } = storeToRefs(adminConfigurationStore)

// Array of refs for v-file-inputs
// const fileInputs = ref([]);

watchEffect(() => {
    adminConfigurationStore.getCountryIspList()
    adminConfigurationStore.getOpenvpn()
})


watchEffect(() => {
    countryList.value.map(country => {
        if(country.order > currentOrderValue) currentOrderValue = country.order;
    })
}, [countryList.value[0]?.name])

watchEffect(() => {
    if (ispList.value) {
        initialISP.value = ispList.value.map(item => {
            return {
                ...item,
                isp_image: backendUrl + '/' + item.isp_image
            }
        })
        filteredISP.value = initialISP.value
    }
}, [ispList.value])

const selectedCountry = (item) => {
    countryList.value = countryList.value.map(country => {
        return item.name === country.name ? { ...country, selected: true } : { ...country, selected: false }
    })
    filteredISP.value = initialISP.value.filter(isp => isp.country === item.name)
}
const setOpenvpn = () => {
    if(openvpn.value) openvpn.value = false;
    else openvpn.value = true;
    adminConfigurationStore.setOpenvpn(openvpn.value)
}
const setCountrySellable = (item) => {
    adminConfigurationStore.setCountrySellable(item.name, !item.sellable)
}

const setCountryOrder = async (item) => {
    if(item.order > 0) {
        const order = item.order
        const temp = await countryList.value.map(country => {
            if(!country?.order) country.order = 0;
            if(country.order > order) country.order--
            if(country.name === item.name) country.order = 0;
            return country
        })
        countryList.value = temp;
        item.order = 0;
        adminConfigurationStore.setAllCountryOrder(countryList.value)
        currentOrderValue--;
    } else {
        currentOrderValue++;
        item.order = currentOrderValue;
        adminConfigurationStore.setCountryOrder(item.name, currentOrderValue)
    }
}

const setIspSellable = (item) => {
    adminConfigurationStore.setIspSellable(item.id, !item.sellable)
}
// Function to handle file input change
const onFileChange = (event, item) => {
    const file = event.target.files[0];
    if (!file) return; // If no file selected, exit
    item.isp_image = URL.createObjectURL(file);
    const formData = new FormData()
    formData.append('isp_id', item.id)
    formData.append('file', file);
    adminConfigurationStore.changeIspImage(formData)
};

// Function to open file input
const openFileInput = (index, item) => {
    document.getElementsByName(`fileInput_${item.id}`)[0].click()
};
</script>