
<template>
  <BreadCrumb PageTitle="Server" />
  <div class="row" v-if="user">
    <div class="col-xxl-12">
      <ServerContent />
    </div>
  </div>
</template>

<script setup>

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ServerContent from "../../components/Admin/Server/ServerContent.vue";

import { useAccountStore } from "@/stores"
import { storeToRefs } from 'pinia';
const accountStore = useAccountStore();
const { user } = storeToRefs(accountStore);
</script>