<template>
    <div class="modal fade" id="autoRotationDialog" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  w-[400px] ">
            <div class="modal-content" v-if="minTime">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Update auto rotation time</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p class="py-2 text-sm">Toggle to enable/disable auto rotation</p>
                    <v-switch v-model="rotationEnabled" label="Auto Rotation" color="success"></v-switch>
                    <input :disabled="!rotationEnabled" placeholder="Input auto rotation time (minutes)" type="number" 
                        v-model="rotationTimeValue" class="form-control shadow-none rounded-0 text-black"
                        />
                    <span>minimal {{minTime}}min</span>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button @click="update"  :disabled="buttonDisableFlag" class="btn btn-primary">Update</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref ,watchEffect, defineProps, defineEmits } from 'vue';
import { useProxyStore, useAlertStore } from "@/stores"
const rotationEnabled = ref(false); // Initially, rotation is disabled
const rotationTimeValue = ref(5);
const buttonDisableFlag = ref(false)
const proxyStore = useProxyStore()
const alertStore = useAlertStore()
const props = defineProps({
    uuid: {
        type: String, // Assuming user object has a name property
        required: false
    },
    min_time_change_ip: {
        type: Number,
        required: false
    },
    rotation_time: {
        type: Number,
        required: false
    }
});
const minTime = ref(!props.min_time_change_ip?5:props.min_time_change_ip)
const emit = defineEmits(['close'])
// if(rotation_time > 0) {
//     rotationEnabled.value = true;
//     rotationTimeValue.value = rotation_time;
// }

const update = () => {
    if(props.uuid&&props.min_time_change_ip !== null){
        if (rotationEnabled.value) {
            if (rotationTimeValue.value >= minTime.value) {
                proxyStore.updateAutoRotationTime(props.uuid, rotationTimeValue.value).then(res => {
                    emit('close');
                });
            }
            else {
                alertStore.error("Min time error")
            }
        }
        else {
            buttonDisableFlag.value = true
            proxyStore.updateAutoRotationTime(props.uuid, 0).then(res => {
                buttonDisableFlag.value = false
                emit('close');
            });
        }
    }
    
}
watchEffect(() => {
    if(props.rotation_time > 0) {
        rotationEnabled.value = true;
        rotationTimeValue.value = props.rotation_time;
    }
})
</script>