import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '.';
// import { updateLicenses } from './account.store';
// import router from '@/router';

const baseUrl = `${process.env.VUE_APP_VUE_API_URL}`;

export const usePackageStore = defineStore('package', {
    state: () => ({
        countryList: [],
        packageList: [],
        plans: {
            monthly: null,
            weekly: null,
            daily: null
        }
    }),
    actions: {
        async getPackageList(searchQuery) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/package/list`, { searchQuery });
                this.packageList = res.packageList;
                // await router.push(this.returnUrl || '/dashboard');
            } catch (error) {
                alertStore.error(error);
            }
        },

        async getItemList(fieldname, searchQuery) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/package/get-item-list`, { fieldname, searchQuery });
                return { [fieldname]: res.fieldList }
                // this.fieldList = {...this.fieldList, [fieldname]:res.fieldList};
                // await router.push(this.returnUrl || '/dashboard');

            } catch (error) {
                alertStore.error(error);
            }
        },
        async getCountryList(serverType) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/package/get-country-list`,{serverType});
                this.countryList = res.result
                // this.fieldList = {...this.fieldList, [fieldname]:res.fieldList};
                // await router.push(this.returnUrl || '/dashboard');

            } catch (error) {
                alertStore.error(error);
            }
        },
        async getCityList(countryName, isp, serverType) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/package/get-city-list`, { countryName, isp, serverType });
                return res.result
                // this.fieldList = {...this.fieldList, [fieldname]:res.fieldList};
                // await router.push(this.returnUrl || '/dashboard');
            } catch (error) {
                alertStore.error(error);
            }
        },
        async getISPList(countryName,serverType) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/package/get-isp-list`, { countryName,serverType });
                return res.result
                // this.fieldList = {...this.fieldList, [fieldname]:res.fieldList};
                // await router.push(this.returnUrl || '/dashboard');
            } catch (error) {   
                alertStore.error(error);
            }
        },
        async getPlans(countryName, isp, cityName, modems_id) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/package/get-plans`, { countryName, isp, cityName, modems_id });
                this.plans.daily = res.day
                this.plans.weekly = res.week
                this.plans.monthly = res.month
                return {
                    daily: res.day,
                    weekly: res.week,
                    monthly: res.month,
                }
            } catch (error) {
                alertStore.error(error);
            }
        },
        // Other actions...
    },
});