
<template>
  <BreadCrumb PageTitle="Referrer" />
  <div class="row" v-if="user">
    <div class="col-xxl-12">
      <ReferralContent />
    </div>
  </div>
</template>

<script setup>
// import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ReferralContent from "../../components/Admin/Customer/ReferralContent.vue";

import { useAccountStore } from "@/stores"
import { storeToRefs } from 'pinia';
const accountStore = useAccountStore();
const { user } = storeToRefs(accountStore);
</script>