import { createWebHistory, createRouter } from "vue-router";
import { useAccountStore } from '@/stores';

import BuyNewProxyPage from "../pages/Customer/BuyNewProxyPage.vue";
import MyProxyPage from "../pages/Customer/MyProxyPage.vue";
import MyReferralPage from "../pages/Customer/ReferralPage.vue";
import RechargePage from "../pages/Customer/RechargePage.vue";
import AccountPage from "../pages/Customer/AccountPage.vue";
import AccountSettingPage from "../pages/Customer/AccountSettingPage.vue";
import AdminServerPage from "../pages/Admin/ServerPage.vue";
import AdminProxyPage from "../pages/Admin/ProxyPage.vue";
import AdminLicensePage from "../pages/Admin/LicensePage.vue";
import AdminPackagePage from "../pages/Admin/PackagePage.vue";
import AdminCouponPage from "../pages/Admin/CouponPage.vue";
import AdminCustomerPage from "../pages/Admin/CustomerPage.vue";
import AdminTransactionPage from "../pages/Admin/TransactionPage.vue";
import AdminConfigurationPage from "../pages/Admin/ConfigurationPage.vue";
import AdminResellerPage from "../pages/Admin/ResellerPage.vue";
import AdminReferrerPage from "../pages/Admin/ReferrerPage.vue";
import AdminMetaPage from "../pages/Admin/MetaPage.vue";
import AdminChat from "../pages/Admin/ChatPage.vue";
import FAQPage from "../pages/FAQPage.vue";

import LoginPage from "../pages/Authentication/LoginPage.vue";
import RegisterPage from "../pages/Authentication/RegisterPage.vue";
import ForgotPasswordPage from "@/pages/Authentication/ForgotPasswordPage.vue";
import ResetPasswordPage from "@/pages/Authentication/ResetPasswordPage.vue";
const routes = [
  {
    path: "/buy-new-proxy",
    name: "BuyNewProxyPage",
    component: BuyNewProxyPage,
  },
  {
    path: "/my-proxy",
    name: "MyProxyPage",
    component: MyProxyPage,
  },
  {
    path: "/recharge",
    name: "RechargePage",
    component: RechargePage,
  },
  {
    path: "/account",
    name: "AccountPage",
    component: AccountPage,
  },
  {
    path: "/referral",
    name: "MyReferralPage",
    component: MyReferralPage,
  },
  {
    path: "/account-setting",
    name: "AccountSettingPage",
    component: AccountSettingPage,
  },
  {
    path: "/",
    name: "",
    component: BuyNewProxyPage,
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/register",
    name: "RegisterPage",
    component: RegisterPage,
  },
  {
    path: "/reset",
    name: "ResetPage",
    component: ResetPasswordPage,
  },
  {
    path: "/forgotpass",
    name: "ForgotPage",
    component: ForgotPasswordPage,
  },
  {
    path: "/admin/server",
    name: "AdminServerPage",
    component: AdminServerPage,
  },
  {
    path: "/admin/proxy",
    name: "AdminProxyPage",
    component: AdminProxyPage,
  },
  {
    path: "/admin/license",
    name: "AdminLicensePage",
    component: AdminLicensePage,
  },
  {
    path: "/admin/package",
    name: "AdminPackagePage",
    component: AdminPackagePage,
  },
  {
    path: "/admin/coupon",
    name: "AdminCouponPage",
    component: AdminCouponPage,
  },
  {
    path: "/admin/customer",
    name: "AdminCustomerPage",
    component: AdminCustomerPage,
  },
  {
    path: "/admin/transaction",
    name: "AdminTransactionPage",
    component: AdminTransactionPage,
  },
  {
    path: "/admin/reseller",
    name: "AdminResellerPage",
    component: AdminResellerPage,
  },
  {
    path: "/admin/referrer",
    name: "AdminReferrerPage",
    component: AdminReferrerPage,
  },
  {
    path: "/admin/configuration",
    name: "AdminConfigurationPage",
    component: AdminConfigurationPage,
  },
  {
    path: "/faq",
    name: "FAQPage",
    component: FAQPage,
  },
  {
    path: "/admin/chat",
    name: "AdminChat",
    component: AdminChat,
  },
  {
    path: "/admin/siteinfo",
    name: "AdminMeta",
    component: AdminMetaPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes: routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

router.beforeEach(async (to) => {
  // clear alert on route change
  // const alertStore = useAlertStore();
  // alertStore.clear();
  // redirect to signin page if not logged in and trying to access a restricted page 
  const publicPages = ['/login', '/register', '/forgotpass', '/reset'];
  const authRequired = !publicPages.includes(to.path);
  const accountStore = useAccountStore();
  // const alertStore = useAlertStore();
  // alert(authRequired+","+!accountStore.user)
  if (authRequired &&( !accountStore.user)) {
      accountStore.returnUrl = to.fullPath;
      accountStore.route_name = ""
      return '/login';
  } else if(accountStore.user){
      if (accountStore.user.role !== 'admin') {
          if (to.path.startsWith("/admin")) {
              accountStore.route_name = ""
              return '/buy-new-proxy';
          }
          else if (to.path.startsWith("/login") || to.path.startsWith("/register") || to.path.startsWith("/reset") || to.path.startsWith("/forgotpass") ||to.path==="/") {
              accountStore.route_name = "buy-new-proxy"
              return '/buy-new-proxy';
          }
      } else {
          if (!to.path.startsWith("/admin")&&!to.path.startsWith("/account")&&!to.path.startsWith("/faq")) {
              accountStore.route_name = "admin"
              return '/admin';
          }
      }
  } 
  // set route name
  accountStore.route_name = to.path;
});

export default router;