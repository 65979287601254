import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '.';
import router from '@/router';
import { connectToWebSocket, disConnectToWebSocket } from './socket.store';

const baseUrl = `${process.env.VUE_APP_VUE_API_URL}`;
const siteKey = process.env.VUE_APP_VUE_RECAPTCHA_V2_SITE_KEY;
export const useAccountStore = defineStore('account', {
    state: () => ({
        user: JSON.parse(localStorage.getItem('user')),
        totalLicences: 0,
        totalLicencesByToday: 0,
        returnUrl: null,
        reCaptchaSiteKey: siteKey,
        unread: 0,
        loading: false,
        transactions: [],
        commission: 0,
        siteTitle: '',
        tglink: '',
        siteInfo: '',
        notificationMessage: '',
    }),
    actions: {
        async setUnread(count) {
            try {
                this.unread = count;
            } catch (error) {
                console.error(error)
            }
        },
        async getTgLink() {
            try{
                const res = await fetchWrapper.get(`${baseUrl}/account/tglink`)
                this.tglink = res.tglink;
            } catch(error) {
                console.error(error)
            }
        },
        async getCommission() {
            const alertStore = useAlertStore();
            try{
                const res = await fetchWrapper.get(`${baseUrl}/account/commission`)
                this.commission = res.commission;
            } catch(error) {
                alertStore.error(error);
            }
        },
        async download(fileURL) {
            const alertStore = useAlertStore();
            try{
                const res = await fetchWrapper.get(`${baseUrl}/account/download?url=${encodeURIComponent(fileURL)}`)
                return res.file
            } catch(error) {
                alertStore.error(error);
            }
        },
        async getMeta() {
            const alertStore = useAlertStore();
            try{
                const res = await fetchWrapper.get(`${baseUrl}/account/meta`)
                this.siteInfo = res.siteInfo;
                this.siteTitle = res.siteTitle;
                this.notificationMessage = res.notificationMessage;
            } catch(error) {
                alertStore.error(error)
            }
        },
        async signin(email, password) {
            const alertStore = useAlertStore();
            try {
                this.loading = true;
                const res = await fetchWrapper.post(`${baseUrl}/account/signin`, { email, password });
                this.user = res.user;
                localStorage.setItem('user', JSON.stringify(res.user));
                await router.push(this.returnUrl || '/buy-new-proxy');
                alertStore.success("You have successfully signed in.");
                if(res&&res.user&&res.user.username){
                    connectToWebSocket(res.user.user_name)
                }
                this.loading = false;
            } catch (error) {
                this.loading = false;
                alertStore.error(error);
            }
        },
        async loadReferralTransactions() {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.get(`${baseUrl}/account/referral-transactions`);
                this.transactions = res.transactions;
            } catch (error) {
                alertStore.error(error);
            }
        },
        async getAccount() {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.get(`${baseUrl}/account/get`);
                this.user = res.user;
                // store user details and jwt in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(res.user));

            } catch (error) {
                alertStore.error(error);
            }
        },
        async forgotpassword(values) {
            const alertStore = useAlertStore();
            const {
                email
            } = values
            try {
                const user = await fetchWrapper.post(`${baseUrl}/account/forgot`, {
                    email,
                    role: 'user'
                });
                if (user.message === "Success") {
                    alertStore.success("Please check your email.");
                }
            } catch (error) {
                alertStore.error(error);
                return false;
            }
        },
        async verifyid(values) {
            const alertStore = useAlertStore();
            const {
                email,
                resetid
            } = values
            try {
                const user = await fetchWrapper.post(`${baseUrl}/account/verifyid`, {
                    email,
                    resetid,
                });
                if (user.message === "Success") {
                    return true;
                } else {
                    return false
                }
            } catch (error) {
                alertStore.error(error);
                return false;
            }
        },
        async resetpassword(values) {
            const alertStore = useAlertStore();
            const {
                email, password
            } = values
            try {
                const user = await fetchWrapper.post(`${baseUrl}/account/resetpassword`, {
                    email,
                    password,
                });
                if (user.message === "Success") {
                    alertStore.success("Reset password successed");
                } 
            } catch (error) {
                alertStore.error(error);
                return false;
            }
        },
        async signup(values, referral_code) {
            this.loading = true;
            const alertStore = useAlertStore();
            const {
                email,
                user_name,
                password,
                name,
                address,
                city,
                country,
                tglink
            } = values
            try {
                const user = await fetchWrapper.post(`${baseUrl}/account/signup`, {
                    email,
                    user_name,
                    password,
                    name,
                    address,
                    city,
                    country,
                    role: 'user',
                    referral: referral_code,
                    tglink: tglink
                });
                if (user.message === "User was registered successfully!") {
                    if (user.user.email === email) {
                        await router.push('/login');
                        alertStore.success("You have successfully signed up.");
                    }
                }
                this.loading = false;
            } catch (error) {

                this.loading = false;
                alertStore.error(error);
                return false;
            }
        },

        async signout() {
            disConnectToWebSocket(this.user.user_name)
            this.user = null;
            localStorage.removeItem('user');
            await router.push('/login');
            const alertStore = useAlertStore();
            alertStore.success("You have signed out.");
        },


        async updateAccountAll(values) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/account/update-all`, { values });
                this.user = res.user;
                // store user details and jwt in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(res.user));
                alertStore.success("You have changed your Account infomation successfully");


            } catch (error) {
                alertStore.error(error);
            }
        },
        async updatePassword(old_password, new_password) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/account/updatePassword`, { old_password, new_password });
                // update pinia state

                this.user = res.user;
                // store user details and jwt in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(res.user));
                alertStore.success("You have changed your password successfully");

                // await router.push(this.returnUrl || '/dashboard');

            } catch (error) {
                alertStore.error(error);
            }
        },
        async purchaseByBalanceFee(plan_uuid, quantity, username, ipAddress, city, openVPN, couponCode) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/package/purchase-by-balance`, { plan_uuid, quantity, username, ipAddress, city, openVPN, couponCode });
                this.user = res.user;
                updateLicenses()

                localStorage.setItem('user', JSON.stringify(res.user));
                await router.push(this.returnUrl || '/buy-new-proxy');
                // alertStore.success("You have purchased successfully");
                return 1;
            } catch (error) {
                if(error === "Insufficient balance"){
                    return 0;
                }
                alertStore.error(error);
                return -1;
            }
        },
        async getLicenses() {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.get(`${baseUrl}/license/get-license-counts`)
                this.totalLicences = res.licences.totalLicences
                this.totalLicencesByToday = res.licences.totalLicencesByToday
            } catch (error) {
                alertStore.error(error.message)
            }
        },

    },
});

export const updateAccount = (key, value) => {
    useAccountStore().updateAccountAll(key, value)
}
export const updateLicenses = () => {
    useAccountStore().getLicenses()
}
