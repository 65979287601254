<template>
  <v-data-table :headers="headers"  items-per-page="-1" id="tablecontainer"
    :items="transactions" hide-default-footer
    item-key="name" class="border h-[500px] bg-white text-black min-h-[500px]" 
    item-value="date" style="font-size: 13px;" :style="`height: ${computedHeight}px;`">

      <template v-slot:[`item.no`]="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ formatDate(item.date) }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{ Number(item.amount) + (item?.commission ? Number(item.commission) : 0) + (item?.costprice ? Number(item.costprice) : 0) }}
      </template>
  </v-data-table>
</template>

<script setup>
import { ref, onMounted, defineProps, onBeforeUnmount } from 'vue';
import { useAccountStore } from "@/stores"
import { storeToRefs } from 'pinia';

const props = defineProps({
  filterName: {
    type: Object, // Assuming user object has a name property
    required: true
  },
  filterEmail: {
    type: Object, // Assuming user object has a name property
    required: true
  },
  filterType: {
    type: Object, // Assuming user object has a name property
    required: true
  },
  filterStatus: {
    type: Object, // Assuming user object has a name property
    required: true
  },
});

const headers = ref(
  [
    { title: 'No', align: 'center', key: 'no' },
    { title: 'User Name', align: 'center', key: 'customer_name' },
    { title: 'Email', align: 'center', key: 'customer_email' },
    { title: 'Amount(USD)', align: 'center', key: 'amount' },
    { title: 'Referral Commission', align: 'center', key: 'commission' },
    { title: 'Type', align: 'center', key: 'type' },
    { title: 'Status', align: 'center', key: 'status' },
    { title: 'Date', align: 'center', key: 'date' },
  ]
);

const offsetHeight = 300; // Adjust this value as needed

const computedHeight = ref(window.innerHeight - offsetHeight);

const accountStore = useAccountStore()
const { transactions } = storeToRefs(accountStore)


const handleResize = () => {
  computedHeight.value = window.innerHeight - offsetHeight;
};


onMounted(() => {
  window.addEventListener('resize', handleResize);
  accountStore.loadReferralTransactions();

//   tableContainer.value.addEventListener('scroll', onScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

const formatDate = (date_m) => {
  const date = new Date(date_m);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${day}/${month}/${year}  ${hours}:${minutes}:${seconds}`;
}

</script>