import { initializeApp } from "firebase/app";
import { addDoc, collection, getFirestore, query, orderBy, startAfter, doc, updateDoc, where, limit, onSnapshot, getDocs, writeBatch } from "firebase/firestore";
import Filter from "bad-words";
import { ref, watch, onMounted } from "vue";
import { useAlertStore } from "./stores";
import { firebaseOption } from "../conf";
import { Howl } from 'howler';
import { fetchWrapper } from '@/helpers';
const baseUrl = `${process.env.VUE_APP_VUE_API_URL}`;
// import { createPinia } from 'pinia'
// import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
// const pinia = createPinia();
// pinia.use(piniaPluginPersistedstate);

// const app = createApp(App)
// /*app.config.productionTip = false*/
// app.use(pinia)

const firebase = initializeApp(firebaseOption); // login proproxy

// const firebase = initializeApp({
//     apiKey: "AIzaSyAWCAzjPnsilHC9PZhYhbGrAZFS9pTstMs",
//     authDomain: "gproxy-linax.firebaseapp.com",
//     projectId: "gproxy-linax",
//     storageBucket: "gproxy-linax.appspot.com",
//     messagingSenderId: "24423440444",
//     appId: "1:24423440444:web:c04b088b9f77e12afde339",
//     measurementId: "G-WN1K8YT5WT"
// }); // 4G proproxy


// const firebase = initializeApp({
//     apiKey: "AIzaSyC4Pnxrf7LV_bFZcKQgFCvOaXiZ6d7alas",
//     authDomain: "test2-3875a.firebaseapp.com",
//     projectId: "test2-3875a",
//     storageBucket: "test2-3875a.appspot.com",
//     messagingSenderId: "962949972877",
//     appId: "1:962949972877:web:26138c16fe3f0771fc33c4",
//     measurementId: "G-11MDN0PGTJ"
// }); // test

const notificationSound = new Howl({
    src: [require('./assets/notify.mp3')],
    volume: 1.0,
    html5: true
});


const db = getFirestore(firebase);

const messages = ref([]);
const userId = ref('');
const unread_count = ref(0);
const filter = new Filter();
let unsubFrom = null;
const lastVisibleMessage = ref(null);
let isAdmin = false;
const allCount = ref(0);
let a = 0, b = 0;
const unreadCounts = ref([]);
const q = query(collection(db, 'adminMessageRead'), orderBy("timestamp", "desc"));

async function getAccurateUtcTime() {
    try {
        const res = await fetchWrapper.get(`${baseUrl}/account/time`);
        // const response = await fetch('https://worldtimeapi.org/api/timezone/etc/UTC');
        // const data = await response.json();
    
        // Create a Date object directly from the ISO 8601 string
        const utcDate = new Date(res.time);

        return utcDate;  // Date object still in UTC internally
    } catch (error) {
        return null;
    }
  }

const markMessagesAsReadAdminFunc = async () => {
    const messageQuery = query(collection(db, 'adminMessageRead'), where('from', '==', userId.value));
    const messageSnapshot = await getDocs(messageQuery);
    const timenow = await getAccurateUtcTime()
    if (!messageSnapshot.empty) {
        // If the document exists, update the "timestamp" and "read" fields
        const messageDoc = messageSnapshot.docs[0];
        if(messageDoc.data().unreadcount !== 0){
            await updateDoc(doc(db, 'adminMessageRead', messageDoc.id), {
                timestamp: timenow,
                unreadcount: 0
            });
        }
    }
}

const unsubscribe = onSnapshot(q, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
        const data = change.doc.data();
        // if (data.unreadcount === 0)

        const existingIndex = unreadCounts.value.findIndex(item => item.id === data.from);
        
        if (change.type === "added" && existingIndex === -1) {
            // If it's a new document and not already in the array, push it
            unreadCounts.value.push({id: data.from, count: data.unreadcount, time: data.timestamp});
        } else if (change.type === "modified" && existingIndex !== -1) {
            // If it's an existing document, update the count and time
            unreadCounts.value[existingIndex].count = data.unreadcount;
            unreadCounts.value[existingIndex].time = data.timestamp;
        }
        if(b === 1 && data.unreadcount !== 0 && isAdmin) {
            const alertStore = useAlertStore();
            alertStore.success("Received message");
            const re = notificationSound.play();
        }
    });
    allCount.value = 0;
    unreadCounts.value.map(item => {
        allCount.value += item.count
    })
    if(isAdmin)
        markMessagesAsReadAdminFunc()
    b = 1;
});

export function useChat() {

    const setUserId = (id, admin) => {
        if(admin) isAdmin = admin
        if(id) userId.value = id;
    };

    const sendMessage = async (text, from, to) => {
        const timenow = await getAccurateUtcTime()
        if(!timenow){
            const alertStore = useAlertStore();
            alertStore.error("Error fetching accurate time from server. Please refresh your site.");
            return
        }
        await addDoc(collection(db, "chatting"), {
            text: filter.clean(text),
            from: from,
            to: to,
            read: false,
            participants: [from, to],
            timestamp: timenow
        });
        const messageQuery = query(collection(db, 'adminMessageRead'), where('from', '==', from));
        const messageSnapshot = await getDocs(messageQuery);
        if(!isAdmin){
            if (!messageSnapshot.empty) {
                // If the document exists, update the "timestamp" and "read" fields
                const messageDoc = messageSnapshot.docs[0];
                await updateDoc(doc(db, 'adminMessageRead', messageDoc.id), {
                    timestamp: timenow,
                    unreadcount: messageDoc.data().unreadcount+1
                });
            } else {
                // If the document does not exist, add a new document to the "adminMessageRead" collection
                await addDoc(collection(db, 'adminMessageRead'), {
                    from: from,
                    timestamp: timenow,
                    unreadcount: 1
                });
            }
            // 
        }
    };

    const markMessagesAsRead = async () => {
        const q = query(collection(db, "chatting"), where("to", "==", userId.value), where("read", "==", false));
        const temp = await getDocs(q);
    
        if (!temp.empty) {
            const batch = writeBatch(db); // Use batch to minimize number of write operations
    
            temp.forEach((docSnapshot) => {
                const docRef = doc(db, "chatting", docSnapshot.id);
                batch.update(docRef, { read: true });
            });
    
            await batch.commit();
        }
    };

    const markMessagesAsReadAdmin = markMessagesAsReadAdminFunc;

    const fetchMessages = () => {
        // Query for messages where 'from' is equal to userId

        // Query for messages where 'to' is equal to userId

        const handleSnapshot = (snapshot) => {
            
            // Extract new messages from the snapshot
            const newMessages = snapshot.docChanges().map(change => {
                if (change.type === 'added' || change.type === 'modified') {
                    if(change.type === 'added'){
                        if(a === 1){
                            if(!isAdmin) {
                                if(change.doc.data().to === userId.value) {
                                    const alertStore = useAlertStore();
                                    alertStore.success("Received message")
                                    notificationSound.play();
                                    
                                }
                            }
                        }
                    }
                    return { ...change.doc.data(), id: change.doc.id };
                }
                return null;
            }).filter(message => message !== null);
        
            // Update messages.value with unique messages
            const updatedMessages = [...messages.value];
            newMessages.forEach(newMessage => {
                const existingMessageIndex = updatedMessages.findIndex(message => message.id === newMessage.id);
                if (existingMessageIndex === -1) {
                    // If the message does not exist, add it to the array
                    updatedMessages.push(newMessage);
                } else {
                    // If the message already exists, replace it
                    updatedMessages[existingMessageIndex] = newMessage;
                }
            });
        
            a = 1;
            // Sort messages by timestamp
            updatedMessages.sort((a, b) => b.timestamp - a.timestamp);
        
            // Update messages.value and calculate unread_count
            messages.value = updatedMessages;
            const unreadMessages = updatedMessages.filter(message => message.to === userId.value && !message.read);
            unread_count.value = unreadMessages.length;
            if (snapshot.docs.length > 0) {
                lastVisibleMessage.value = snapshot.docs[snapshot.docs.length - 1];
            }
        };

        watch(userId, async (newUserId) => {
            if (unsubFrom) unsubFrom();
            if (newUserId) {
                messages.value = []
                const combinedQuery = query(collection(db, 'chatting'),
                    where('participants', 'array-contains-any', [newUserId]),
                    orderBy("timestamp", "desc")
                );

                unsubFrom = onSnapshot(combinedQuery, handleSnapshot);
            }
        }, { immediate: true });
        


        // Optionally, you can return unsubscribe functions if needed
        return { unsubFrom };
    };

    const loadMoreMessages = async () => {
        if (!lastVisibleMessage.value) return;
        const fromQuery = query(
            collection(db, "chatting"),
            where('from', '==', userId.value),
            orderBy("timestamp", "desc"),
            startAfter(lastVisibleMessage.value),
            limit(10)
        );

        // const toQuery = query(
        //     collection(db, "chatting"),
        //     where('to', '==', userId.value),
        //     orderBy("timestamp", "desc"),
        //     startAfter(lastVisibleMessage.value),
        //     limit(10)
        // );

        const fetchAdditionalMessages = async (q) => {
            const snapshot = await getDocs(q);
            const newMessages = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

            messages.value = [...messages.value, ...newMessages].sort((a, b) => b.timestamp - a.timestamp);
            if (snapshot.docs.length > 0) {
                lastVisibleMessage.value = snapshot.docs[snapshot.docs.length - 1];
            }
        };

        await fetchAdditionalMessages(fromQuery);
        // await fetchAdditionalMessages(toQuery);
    };

    onMounted(() => {
        fetchMessages();
    });

    return { messages, unreadCounts,  sendMessage, setUserId, allCount, unread_count, loadMoreMessages, markMessagesAsRead, markMessagesAsReadAdmin };
}
