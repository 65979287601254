<template>
  <div class="dark-swtich-btn">
    <button
      class="switch-toggle transition d-inline-block position-relative border-0 rounded-circle"
      id="switch-toggle"
      @click="toggleDarkMode"
    >
      <i class="flaticon-moon"></i>
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useDarkModeStore } from "@/stores";

const isDarkMode = ref(localStorage.getItem('dark') ? localStorage.getItem('dark') : true );
if(isDarkMode.value === true || isDarkMode.value === false)
  document.body.classList.toggle('dark', isDarkMode.value);
const toggleDarkMode = () => {
  isDarkMode.value = !isDarkMode.value;
  useDarkModeStore().toggleDarkMode();
  document.body.classList.toggle('dark', isDarkMode.value);
  localStorage.setItem('dark', isDarkMode.value.toString());
};
const storedDarkMode = localStorage.getItem('dark');
if (storedDarkMode) {
  isDarkMode.value = storedDarkMode === 'true';
  document.body.classList.toggle('dark', isDarkMode.value);
}
</script>