<template>
  <v-data-table :headers="headers"  items-per-page="-1" id="tablecontainer"
    :items="Transactions" totalItems="100" hide-default-footer
    item-key="name" class="border h-[500px] bg-white text-black min-h-[500px]" 
    show-expand item-value="date" style="font-size: 13px;" :style="`height: ${computedHeight}px;`">

      <template v-slot:expanded-row="{ columns, item }">
        <td :colspan=" columns.length ">
          <div class="flex justify-center items-center">
            <div v-html=" item.description ">

            </div>

          </div>
        </td>


      </template>
      <template v-slot:[`item.no`]="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ formatDate(item.date) }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{ Number(item.amount) + (item?.commission ? Number(item.commission) : 0) + (item?.costprice ? Number(item.costprice) : 0) }}
      </template>
      <template #bottom></template>
  </v-data-table>
</template>

<script setup>
import { ref, onMounted, defineProps, watchEffect, onBeforeUnmount } from 'vue';
import { useAdminTransactionStore } from "@/stores"
import { storeToRefs } from 'pinia';

let count = 0;

const props = defineProps({
  filterName: {
    type: Object, // Assuming user object has a name property
    required: true
  },
  filterEmail: {
    type: Object, // Assuming user object has a name property
    required: true
  },
  filterType: {
    type: Object, // Assuming user object has a name property
    required: true
  },
  filterStatus: {
    type: Object, // Assuming user object has a name property
    required: true
  },
});

const headers = ref(
    [
        { title: 'No', align: 'center', key: 'no' },
        { title: 'Customer', align: 'center', key: 'customer_name' },
        { title: 'Email', align: 'center', key: 'customer_email' },
        { title: 'Amount(USD)', align: 'center', key: 'amount' },
        { title: 'Type', align: 'center', key: 'type' },
        { title: 'Status', align: 'center', key: 'status' },
        { title: 'Previous Balance', align: 'center', key: 'previous_balance' },
        { title: 'New Balance', align: 'center', key: 'new_balance' },
        { title: 'Pay from', align: 'center', key: 'pay_address' },
        { title: 'Pay_amount', align: 'center', key: 'pay_amount' },
        { title: 'Pay_currency', align: 'center', key: 'pay_currency' },
        { title: 'Date', align: 'center', key: 'date' },
    ]
);

const offsetHeight = 400; // Adjust this value as needed

const computedHeight = ref(window.innerHeight - offsetHeight);

const adminTransactionStore = useAdminTransactionStore()
const { Transactions } = storeToRefs(adminTransactionStore)


const handleResize = () => {
  computedHeight.value = window.innerHeight - offsetHeight;
};


onMounted(() => {
  window.addEventListener('resize', handleResize);
  count = 50;
  adminTransactionStore.loadTransactions({name: props.filterName, email: props.filterEmail, type: props.filterType, status: props.filterStatus, from: 0, count: 50});
  const element = document.getElementById('tablecontainer').children[0]
  element.addEventListener('scroll', onScroll)
  
//   tableContainer.value.addEventListener('scroll', onScroll);
});


const onScroll = (event) => {
  const table = event.target;
  if (table.scrollTop + table.clientHeight >= table.scrollHeight) {
    adminTransactionStore.loadTransactions({name: props.filterName, email: props.filterEmail, type: props.filterType, status: props.filterStatus, from: count, count: 20});
    count += 20;
  }
};

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
  const element = document.getElementById('tablecontainer').children[0]
  element.removeEventListener('scroll', onScroll)
});

const formatDate = (date_m) => {
  const date = new Date(date_m);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${day}/${month}/${year}  ${hours}:${minutes}:${seconds}`;
}

</script>