<template>
  <div class="modal fade" id="customerAddModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered  w-[500px] ">
          <Form @submit="onSubmit" :validation-schema="schema" class="w-full">

              <div class="modal-content">
                  <div class="modal-header">
                      <h1 class="modal-title fs-5">Create New User</h1>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body ">
                      <div class="overflow-hidden">
                          <div class="float-left w-full p-2 h-20">
                              Email:
                              <v-text-field v-model="email" variant="outlined" density="compact"
                                  rounded="0"></v-text-field>
                          </div>
                      </div>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                      <button type="submit" :disabled="buttonDisableFlag" class="btn btn-primary">Add</button>
                  </div>
              </div>
          </Form>

      </div>
  </div>
</template>



<script setup>
import { ref, defineEmits } from "vue";
import { useAdminUserStore } from "@/stores";
import * as Yup from 'yup';
import { Form, Field } from 'vee-validate';

const email = ref("");


const buttonDisableFlag = ref(false)
const emit = defineEmits(['inInput', 'submit'])

const adminUserStore = useAdminUserStore();

const onSubmit = (values) => {
  const createRequest = {
      email: email.value
  };
  buttonDisableFlag.value = true
  adminUserStore.addResellerUser(createRequest).then(() => {
      buttonDisableFlag.value = false
      emit('updated')
  });
};

</script>
