<template>
  
  <div id="chart" class=" p-2 w-full">
    <div class="chart w-full">
      <apexchart v-if="chartOptions.xaxis.categories" type="line" height="500" id="weeklySalesChart" :options="chartOptions" :series="series"></apexchart>
    </div>
  </div>
</template>
<script setup>




import { ref, watchEffect, defineProps } from "vue"
import { useAdminServerStore } from "@/stores"
import { storeToRefs } from 'pinia';

const adminServerStore = useAdminServerStore()
const { charts } = storeToRefs(adminServerStore)
const graphRef = ref(null)
const props = defineProps({
  filters: {
    type: Object, // Assuming user object has a name property
    required: true
  },
});
const filters = ref(props.filters)
watchEffect(() => {
  adminServerStore.fetchCharts(filters.value)
}, [filters])

const series = ref(null);
const monthNameList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let chartOptions = ref({
  chart: {
    height: "350",
    type: 'scatter',
    zoom: {
      enabled: false
    },
    toolbar: {
      show: false
    }
  },
  stroke: {
    width: 2,
    curve: 'smooth',
    dashArray: 0
  },
  legend: {
    tooltipHoverFormatter: (val, opts) => {
      const amount = val === "Revenue" ? "$" + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] : opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex];
      return val + ' - <strong>' + amount + '</strong>';
    },
    position: "top",
    fontSize: "14px",
    fontWeight: 500,
    horizontalAlign: "center",
    fontFamily: "Red Hat Display, sans-serif",
    labels: {
      colors: "#8E8DA1",
    },
    markers: {
      offsetY: -1,
      offsetX: -4,
      height: 15,
      width: 15,
    },
    itemMargin: {
      horizontal: 10,
    },
  },
  colors: ['#f88', '#0ff'],
  markers: {
    size: 5,
    hover: {
      sizeOffset: 6
    }
  },
  xaxis: {
    categories: [],//charts.value.amount.map(item=>monthNameList[item.month-1]),//["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    labels: {
      show: true,
      style: {
        fontFamily: "Red Hat Display, sans-serif",
        colors: "#9C9AB6",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    tooltip: {
      style: {
        fontFamily: "Red Hat Display, sans-serif",
        colors: "#2b2a3f",
        fontSize: "14px",
      },
    },
  },
  yaxis: [
    {
      labels: {
        style: {
          fontFamily: "Red Hat Display, sans-serif",
          colors: ["#9C9AB6"],
          fontSize: "14px",
          fontWeight: 500,
        },
        formatter: (value) => value, // Format y-axis labels for Quantity Sold series
      },
    },
    {
      opposite: true,
      labels: {
        style: {
          fontFamily: "Red Hat Display, sans-serif",
          colors: ["#9C9AB6"],
          fontSize: "14px",
          fontWeight: 500,
        },
        formatter: (value) => value, // Format y-axis labels for Revenue series
      },
    },
  ],
  tooltip: {
    fixed: {
      enabled: true,
      position: 'topLeft',
      offsetX: 100,
      offsetY: 0,
    },
    y: [
      {
        title: {
          formatter: (val, { seriesIndex, dataPointIndex, w }) => {
            return val + " $"; // Return dollar value for the Revenue data
          },
        },
      },
      {
        title: {
          formatter: (val) => {
            return val + "";
          }
        }
      },
      {
        title: {
          formatter: (val) => {
            return val;
          }
        }
      }
    ],
  },

});


watchEffect(() => {
  series.value = [
    {
      name: "Revenue",
      data: charts.value.amount.map(item => item.total_amount)
    },
    {
      name: 'Quantity Sold',
      data: charts.value.count.map(item => item.count)
    }
  ]
  if(charts.value.amount.length>0){
    chartOptions.value.xaxis.categories = charts.value.amount.map(item=>monthNameList[item.month-1])
  }
}, [charts.value]) 
</script>