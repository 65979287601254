
<template>
  <BreadCrumb PageTitle="FAQ" />
  <div class="row" v-if="user">
    <div class="col-xxl-12">
      <FAQContent />
    </div>
  </div>
</template>

<script setup>
import BreadCrumb from "../components/Common/BreadCrumb.vue";
import FAQContent from "../components/Faq/FaqAccordion.vue";

import { useAccountStore } from "@/stores"
import { storeToRefs } from 'pinia';
const accountStore = useAccountStore();
const { user } = storeToRefs(accountStore);
</script>