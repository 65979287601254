
<template>
  <BreadCrumb PageTitle="Proxy" />
  <div class="row" v-if="user">
    <div class="col-xxl-12">
      <ProxyContent />
    </div>
  </div>
</template>

<script setup>

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ProxyContent from "../../components/Admin/Proxy/ProxyContent.vue";

import { useAccountStore } from "@/stores"
import { storeToRefs } from 'pinia';
const accountStore = useAccountStore();
const { user } = storeToRefs(accountStore);
</script>