<template>
  <div class="">
      <div class="flex justify-between flex-wrap items-center py-2">
          <div class="w-1/5 min-w-[200px] pr-5 text-xs py-2">
            Search (Coupon code, User Email)
            <v-text-field placeholder="Name" variant="outlined" density="compact" v-model="searchData" rounded="0">
            </v-text-field>
          </div>
          <div>
              <v-btn icon="mdi-delete" size="small" style="outline: none;" @click="deleteCoupons"
                  class="mr-5 bg-dark text-light"></v-btn>
              <v-btn icon="mdi-plus" size="small" style="outline: none;"
                  class="bg-dark text-light" @click="addCoupon" data-bs-toggle="modal"
                  data-bs-target="#couponAddModal"></v-btn>
          </div>
      </div>
      <v-data-table :headers="headers" :items="Coupons.filter(coupon => [coupon.couponCode, coupon.user].join(',').toLowerCase().includes(searchData.toLowerCase()))" item-key="name" class="border h-[500px] bg-white text-black min-h-[500px] " items-per-page="-1"
          style="font-size: 13px;" :style="`height: ${computedHeight}px;`">
          <template v-slot:[`item.no`]="{ index }">
              {{ index + 1 }}
          </template>
          <template v-slot:[`item.checkbox`]="{ item }">
              <div @click="toggleSelection(item)" class="ml-auto align-self-center"
                  style="width: 20px; height: 20px; cursor: pointer;">
                  <input :checked="isSelected(item)" class="form-check-input shadow-none" type="checkbox" />
              </div>
          </template>
          <template v-slot:[`item.buttonBar`]="{ item }">
              <v-menu offset-y location="bottom">
                  <template v-slot:activator="{ props, on, isActive }">
                      <v-btn v-bind="props" v-on="{ ...on }" density="compact"
                          :style="isActive ? 'border-bottom-left-radius: 0px;border-bottom-right-radius: 0px;' : ''"
                          style="outline: none; font-size: 10px; " class="w-32 z-10 bg-dark text-light">
                          Actions
                          <v-icon icon="mdi-chevron-down" size="14"></v-icon>
                      </v-btn>
                  </template>
                  <v-list class="w-32 flex-1 flex flex-col px-0 py-0  z-0"
                      style="border-top-left-radius: 0px;border-top-right-radius: 0px">
                      <v-btn v-for="(button, index) in actionButtons" :key="index" @click="clickAction(item, button)"
                          rounded="0" class="text-center bg-dark text-light"
                          style="font-size: 10px; width: 128px; outline: none; border-bottom: 1px solid #345;"
                          :data-bs-toggle="button === 'Update' && 'modal'"
                          :data-bs-target="button === 'Update' && '#couponUpdateModal'">
                          {{button }}
                      </v-btn>
                  </v-list>
              </v-menu>
          </template>
      </v-data-table>
  </div>
  <div>
      <AddNewOneDialog @close="addNewOneDialogFlag = false" />
      <UpdateInfoDialog :item="selectedItem" @updated="updatedHandler" />
  </div>
</template>
<script setup>
import { ref, onMounted, defineProps, onBeforeUnmount } from 'vue';
import { useAdminCouponStore } from "@/stores"
import { storeToRefs } from 'pinia';
// import SearchBar from './SearchBar.vue';
import AddNewOneDialog from "./Modal/AddNewOneDialog.vue"
import UpdateInfoDialog from "./Modal/UpdateInfoDialog.vue"



const headers = ref(
  [
      { title: 'No', align: 'center', key: 'no' },
      { title: '', align: 'center', key: 'checkbox' },
      { title: 'Coupon Code', align: 'center', key: 'couponCode' },
      { title: 'Expire Date', align: 'center', key: 'expireDate' },
      { title: 'Discount Type', align: 'center', key: 'discountType' },
      { title: 'Value', align: 'center', key: 'discountValue' },
      { title: 'Coupon Type', align: 'center', key: 'couponType' },
      { title: 'User', align: 'center', key: 'user' },
      { title: 'Package Price', align: 'center', key: 'price' },
      { title: 'Actions', align: 'center', key: 'buttonBar' },
  ]
);
const adminCouponStore = useAdminCouponStore()
const { Coupons } = storeToRefs(adminCouponStore)
adminCouponStore.loadCoupons();

const addNewOneDialogFlag = ref(false)
const selectedItems = ref([]);
const searchData = ref('')
const offsetHeight = 300; // Adjust this value as needed

const computedHeight = ref(window.innerHeight - offsetHeight);

const handleResize = () => {
computedHeight.value = window.innerHeight - offsetHeight;
};

onMounted(() => {
window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
window.removeEventListener('resize', handleResize);
});
const isSelected = (item) => {
  return selectedItems.value.includes(item);
};
const toggleSelection = (item) => {
  const index = selectedItems.value.indexOf(item);
  if (index === -1) {
      selectedItems.value.push(item);
  } else {
      selectedItems.value.splice(index, 1);
  }
};
const addCoupon = () => {
  addNewOneDialogFlag.value = true
}
const deleteCoupons = () => {
  adminCouponStore.deleteCoupons(selectedItems.value.map(item => item.couponCode)).then(() => {
    adminCouponStore.loadCoupons()
  })
}


const actionButtons = ref(
  ["Update", "Delete"]
)

const updateInfoDialogFlag = ref(false)
const selectedItem = ref(null)

const openUpdateInfoDialog = (item) => {
  selectedItem.value = item
  updateInfoDialogFlag.value = true
}
const updatedHandler = () => {
  adminCouponStore.loadCoupons()
  updateInfoDialogFlag.value = false
}

const clickAction = (item, button) => {
  switch (button) {
    case "Update":
      openUpdateInfoDialog(item)
      break
    case "Delete":
      adminCouponStore.deleteCoupons([item.couponCode]).then(() => {
        adminCouponStore.loadCoupons()
      })

  }
}
</script>