<template>
    <div class="modal fade" id="transactionInfoModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered w-[650px]">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Transaction Information</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="transferInfo !== null" class="px-2">
                        <div class="px-4 py-4 ">
                            <div class="flex justify-between ">
                                <div class="w-3/4">
                                    <p class="text-lg font-semibold">Transaction Information</p>
                                    <div class="text-sm">
                                        <p class="py-1">
                                            Price: {{ transferInfo.amount }} {{ transferInfo.currency }}
                                        </p>
                                        <p class="py-1">Amount: {{ transferInfo.pay_amount }} {{transferInfo.pay_currency }}
                                        </p>
                                        <div class="py-1 flex items-center">
                                            Address:
                                        <p>{{ transferInfo.address }}</p>
                                        <v-icon icon="mdi-content-copy" size="18" class="mx-2" color="black"
                                            @click="copyText(transferInfo.address)"></v-icon>
                                        </div>
                                    </div>
                                </div>
                                <img :src="transferInfo.qrCode" class="w-1/4  qr" />
                            </div>
                        </div>

                        <div class="mt-10 px-4 py-4">
                            <p class="text-lg font-semibold">Guide</p>
                            <p class="text-sm py-1"><v-icon icon="mdi-check"></v-icon>
                                Please sends coins with the transfer information.</p>
                            <p class="text-sm py-1"><v-icon icon="mdi-check"></v-icon>
                                After sends coins, system will processes and exchanges them, and settles the payment to
                                your
                                balance.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { ref, watchEffect, defineProps } from 'vue';
import { useAlertStore } from "@/stores"
import copy from 'clipboard-copy';
const props = defineProps({

    transferInfo: {
        type: Object,
        required: true
    }
});
const transferInfo = ref(props.transferInfo)
const copyText = (text) => {
    copy(text)
    const alertStore = useAlertStore()
    alertStore.success("Address has been Copied")
}
watchEffect(()=>{
    transferInfo.value = props.transferInfo
},[props.transferInfo])
</script>