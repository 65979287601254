import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!
  const _component_ProfileContent = _resolveComponent("ProfileContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BreadCrumb, { PageTitle: "Profile" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ProfileContent)
      ])
    ])
  ], 64))
}