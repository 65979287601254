import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import { QuillEditor } from '@vueup/vue-quill'
import Vue3Prism from 'vue3-prism/lib/Vue3Prism.common.js'
import vuetify from "./plugins/vuetify";
import { createHead } from '@vueuse/head';

import "bootstrap/dist/css/bootstrap.css";
import "swiper/css";
import "swiper/css/bundle";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import "vue3-prism/lib/Vue3Prism.css"

import '@vuepic/vue-datepicker/dist/main.css';
import { createPinia } from "pinia";

import vueCountryRegionSelect from 'vue3-country-region-select'
import "./assets/custom.scss";
const pinia = createPinia();

const app = createApp(App);
app.use(router);
app.use(vuetify);
app.use(pinia);
app.use(VueApexCharts);
app.use(vueCountryRegionSelect)
// app.use(BootstrapVueNext);
const head = createHead();

app.use(head);
app.component('QuillEditor', QuillEditor)
.use(Vue3Prism)

app.mount("#app");
