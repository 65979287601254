import { defineStore } from 'pinia';

export const useAlertStore = defineStore({
    id: 'alert',
    state: () => ({
        alert: null,
    }),
    actions: {
        success(message) {
            if(message!==undefined)
            this.alert = { message, type: 'success' };
        },
        error(message) {
            if(message.message!=='Failed to fetch'&&message!=='Failed to fetch')
                if(message!==undefined&&message!==400&&message!==401&&message!==null){
                    this.alert = { message, type: 'error' };
            }
        },
        warning(message) {
            if(message!==undefined)
            this.alert = { message, type: 'warning' };
        },
        default(message) {
            if(message!==undefined)
            this.alert = { message, type: 'default' };
        },
        clear() {
            this.alert = null;
        }
    }
});
export const success = (message ) => {
    useAlertStore().success(message)
}