<template>
  <BreadCrumb PageTitle="Profile Settings" />
  <AccountSetting />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import AccountSetting from "../../components/Customer/Account/AccountSetting.vue";

export default defineComponent({
  name: "AccountSettingPage",
  components: {
    BreadCrumb,
    AccountSetting,
  },
});
</script>