<template>
    <div class="flex space-x-2 mx-4">
        <a v-if="tglink" class="flex flex-row items-center gap-x-2 px-3" :href="formatTgLink(tglink)" target="_blank">
            <img class="w-[30px] h-[30px]" src="../../assets/tg.png" />
        </a>
        <span class="badge text-center flex items-center text-bg-primary rounded-pill fs-13">$ {{user.balance?.toFixed(2)}}</span>
    </div>
</template>
<script setup>
import {useAccountStore} from "@/stores"
import {storeToRefs} from "pinia"
const accountStore = useAccountStore()
accountStore.getTgLink();
const {user, tglink} = storeToRefs(accountStore)

const formatTgLink = (tglink) => {
    if(tglink) {
    const cleanedID = tglink.replace(/(@|https?:\/\/t\.me\/)/g, "").trim();
    const telegramUrl = `https://t.me/${cleanedID}`;
    return telegramUrl;
    } else return ''
}

</script>