import { defineStore } from 'pinia';

// export const useDarkModeStore = defineStore({
//   id: 'darkMode',
//   state: () => ({
//     isDarkMode: ref(localStorage.getItem('dark'))
//   }),
//   actions: {
//     toggleDarkMode() {
//       this.isDarkMode = !this.isDarkMode;
//       localStorage.setItem('dark', this.isDarkMode.toString());
//     }
//   },
//   getters: {
//     logoPath() {
//       return this.isDarkMode.value ? '../../assets/logo2.png' : '../../assets/logo.png';
//     }
//   }
// });


export const useDarkModeStore = defineStore('dark', {
    state: () => ({
        isDarkMode: localStorage.getItem('dark') === "false" ? false : true,
    }),
    actions: {
      toggleDarkMode() {
        this.isDarkMode = !this.isDarkMode;
        return
      },
      // Other actions...
    },
});