import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '.';

const baseUrl = `${process.env.VUE_APP_VUE_API_URL}`;
export const useFAQStore = defineStore('faq', {
    state: () => ({
        faqs: []
    }),
    actions: {
        async getAll() {
          const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.get(`${baseUrl}/faq/getall`);
                this.faqs = res;
            } catch (error) {
                alertStore.error(error);
            }
        },
        async addFAQ(values) {
            const alertStore = useAlertStore();
            const {question,answer,count} = values
            try {
                const faq = await fetchWrapper.post(`${baseUrl}/faq/add`, {question,answer,count});
                this.faqs.push(faq)
                alertStore.success("New successfully added")
            } catch (error) {
                alertStore.error(error);
                return false;
            }
        },
        async saveFAQ(id, question, answer) {
            const alertStore = useAlertStore();
            try {
                const faq = await fetchWrapper.post(`${baseUrl}/faq/edit`, {id, question,answer});
                this.faqs = this.faqs.map(item => {
                    if(item.id === faq.id) item = faq;
                    return item;
                })
                alertStore.success("Save changed")
            } catch (error) {
                alertStore.error(error);
                return false;
            }
        },
        async setOrder(value) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/faq/setorder`, value);
                this.faqs = value
            } catch (error) {
                alertStore.error(error);
                return false;
            }
        },
        async deleteFAQ(id) {
          const alertStore = useAlertStore();
          try {
            await fetchWrapper.delete(`${baseUrl}/faq/delete`, {id});
            this.faqs = this.faqs.filter(item => item.id !== id);
            alertStore.success("Delete successful")
          } catch (error) {
            alertStore.error(error);
            return false;
          }
        }

    },
});
