<template>
    <div class="">
        <div class="flex justify-between flex-wrap items-center py-2">
            <SearchBar @name-selected="nameSelectedHandeler">
            </SearchBar>
            <div v-if="selectedServerName !== ''">
                <v-btn icon="mdi-delete" size="small" style="outline: none;" @click="deletePackages"
                    class="mr-5 bg-dark text-light"></v-btn>
                <v-btn v-if="selectedServerName !== 'All'" icon="mdi-plus" size="small" style="outline: none;"
                    class="bg-dark text-light" @click="addProxy" data-bs-toggle="modal"
                    data-bs-target="#packageAddModal"></v-btn>
            </div>
        </div>
        <div v-if="loading === true" class=" flex items-center justify-center absolute custom-style " >
            <img src="../../../assets/loading.gif" class="w-[50px]"/>
        </div>
        <v-data-table :headers="headers" :items="Packages" item-key="name" class="border h-[500px] bg-white text-black min-h-[500px] " items-per-page="-1"
            style="font-size: 13px;" :style="`height: ${computedHeight}px;`">
            <template v-slot:[`item.no`]="{ index }">
                {{ index + 1 }}
            </template>
            <template v-slot:[`item.checkbox`]="{ item }">
                <div @click="toggleSelection(item)" class="ml-auto align-self-center"
                    style="width: 20px; height: 20px; cursor: pointer;">
                    <input :checked="isSelected(item)" class="form-check-input shadow-none" type="checkbox" />
                </div>
            </template>
            <template v-slot:[`item.buttonBar`]="{ item }">
                <v-menu offset-y location="bottom">
                    <template v-slot:activator="{ props, on, isActive }">
                        <v-btn v-bind="props" v-on="{ ...on }" density="compact"
                            :style="isActive ? 'border-bottom-left-radius: 0px;border-bottom-right-radius: 0px;' : ''"
                            style="outline: none; font-size: 10px; " class="w-32 z-10 bg-dark text-light">
                            Actions
                            <v-icon icon="mdi-chevron-down" size="14"></v-icon>
                        </v-btn>
                    </template>
                    <v-list class="w-32 flex-1 flex flex-col px-0 py-0  z-0"
                        style="border-top-left-radius: 0px;border-top-right-radius: 0px">
                        <v-btn v-for="(button, index) in actionButtons" :key="index" @click="clickAction(item, button)"
                            rounded="0" class="text-center bg-dark text-light"
                            style="font-size: 10px; width: 128px; outline: none; border-bottom: 1px solid #345;"
                            :data-bs-toggle="button === 'Update' && 'modal'"
                            :data-bs-target="button === 'Update' && '#packageUpdateModal'">
                            {{button }}
                        </v-btn>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
    </div>
    <div>
        <AddNewOneDialog @close="addNewOneDialogFlag = false" :serverName="selectedServerName" />
        <UpdateInfoDialog :item="selectedItem" @updated="updatedHandler" />
    </div>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useAdminPackageStore } from "@/stores"
import { storeToRefs } from 'pinia';
import SearchBar from './SearchBar.vue';
import AddNewOneDialog from "./Modals/AddNewOneDialog.vue"
import UpdateInfoDialog from "./Modals/UpdateInfoDialog.vue"

import { watchEffect } from 'vue';
const headers = ref(
    [
        { title: 'No', align: 'center', key: 'no' },
        { title: '', align: 'center', key: 'checkbox' },
        { title: 'Package', align: 'center', key: 'name' },
        { title: 'Time Unit', align: 'center', key: 'time_unit' },
        // { title: 'Duration', align: 'center', key: 'duration' },
        { title: 'Price', align: 'center', key: 'price' },
        { title: 'Status', align: 'center', key: 'status' },
        // { title: 'Allow IP Change', align: 'center', key: 'allow_change_ip' },
        // { title: 'Allow IP Location', align: 'center', key: 'allow_change_location' },
        { title: 'Min IP Change Time(min)', align: 'center', key: 'min_time_change_ip' },
        { title: 'ISP', align: 'center', key: 'isp' },
        { title: 'Order', align: 'center', key: 'seq' },
        { title: 'Actions', align: 'center', key: 'buttonBar' },
    ]
);
const adminPackageStore = useAdminPackageStore()
const { Packages, loading } = storeToRefs(adminPackageStore)

const selectedServerName = ref("")
const addNewOneDialogFlag = ref(false)
const selectedItems = ref([]);
const offsetHeight = 300; // Adjust this value as needed

const computedHeight = ref(window.innerHeight - offsetHeight);

const handleResize = () => {
  computedHeight.value = window.innerHeight - offsetHeight;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
const isSelected = (item) => {
    return selectedItems.value.includes(item);
};
const toggleSelection = (item) => {
    const index = selectedItems.value.indexOf(item);
    if (index === -1) {
        selectedItems.value.push(item);
    } else {
        selectedItems.value.splice(index, 1);
    }
};
const nameSelectedHandeler = (name) => {
    if (name) {
        selectedServerName.value = name
        adminPackageStore.loadPackages(name)
    }
}
const addProxy = () => {
    addNewOneDialogFlag.value = true
}
const deletePackages = () => {
    adminPackageStore.deletePackages(selectedItems.value.map(item => item.uuid)).then(() => {
        selectedItems.value = []
        adminPackageStore.loadPackages(selectedServerName.value)
    })

}




const actionButtons = ref(
    ["Update", "Delete"]
)
const updateInfoDialogFlag = ref(false)
const selectedItem = ref(null)

const openUpdateInfoDialog = (item) => {
    selectedItem.value = item
    updateInfoDialogFlag.value = true
}
const updatedHandler = () => {
    adminPackageStore.loadPackages(selectedServerName.value)
    updateInfoDialogFlag.value = false
}

const clickAction = (item, button) => {
    switch (button) {
        case "Update":
            openUpdateInfoDialog(item)
            break
        case "Delete":
            adminPackageStore.deletePackages([item.uuid]).then(() => {
                adminPackageStore.loadPackages(selectedServerName.value)
            })

    }
}
watchEffect(() => {
    if (selectedServerName.value !== '') {
        Packages.value = []
    }
}, [selectedServerName.value])
</script>

<style scoped>
.custom-style {
    width: -webkit-fill-available; /* Safari/WebKit */
    height: -webkit-fill-available; /* Safari/WebKit */
    width: -moz-available; /* Firefox */
    height: -moz-available; /* Firefox */
    width: fill-available; /* Standard syntax, for future compatibility */
    height: fill-available; /* Standard syntax, for future compatibility */
}
</style>