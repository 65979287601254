<template>
  <div v-if="notificationMessage" class="notification-bar">
    <div ref="scrollingText" class="scrolling-text">
      {{ notificationMessage }}
    </div>
  </div>
  <header
   :class="[
    'header-area bg-white text-center text-md-start pl-3 pr-10 transition mb-25 position-fixed ',
    { sticky: isSticky },
  ]" :style="{height: '74px', top: notificationMessage ? '65px' : '25px'}" id="header">
    <div class="row flex items-center h-full">
      <div class="col-xl-2 col-lg-3 col-md-4">
        <div class="header-left-side justify-content-center justify-content-md-start d-flex align-items-center">
          <button class="header-burger-menu transition position-relative lh-1 bg-transparent p-0 border-0 text-black"
            id="header-burger-menu" @click="stateStoreInstance.onChange">
            <i class="flaticon-menu-3"></i>
          </button>
        </div>
      </div>
      <div class="col-xl-10 col-lg-9 col-md-8">
        <div class="header-right-side d-flex align-items-center justify-content-center justify-content-md-end">
          <AccountInfo  v-if="user&&user.role==='user'" />
          <AdminAccountInfo  v-else-if="user&&user.role==='admin'" />
          <v-btn v-if="user&&user.role==='admin'" class=" badge text-bg-primary  rounded-pill fs-13 m-1 block lg:hidden" @click="licenseView" data-bs-toggle="modal" data-bs-target="#licenseDetailModal" >License View</v-btn>
          <LightDarkSwtichBtn />

          <div class="dropdown profile-dropdown">
            <button
              class="dropdown-toggle text-start fs-14 text-black-emphasis d-flex align-items-center p-0 position-relative bg-transparent border-0 transition lh-1"
              type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="../../assets/images/default-user-avatar.png" class="rounded" width="44" height="44"
                alt="admin" />
              <span class="title d-none d-lg-block ms-10 ms-lg-15">
                <span class="d-block fw-bold mb-5 mb-md-8">{{ user.name }}</span>
                <span class="text-body-emphasis fw-semibold fs-13">{{ user.role }}</span>
              </span>
            </button>
            <div class="dropdown-menu rounded-0 bg-white border-0 start-auto end-0">
              <ul class="ps-0 mb-0 list-unstyled dropdown-body">
                <li class="text-body-secondary fw-semibold transition position-relative">
                  <i class="flaticon-user-2"></i>
                  My Account
                  <router-link to="/account"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"></router-link>
                </li>
                <li class="text-body-secondary fw-semibold transition position-relative">
                  <i class="flaticon-setting"></i>
                  Settings
                  <router-link to="/account-setting"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"></router-link>
                </li>
                <li class="text-body-secondary fw-semibold transition position-relative cursor-pointer"
                  @click="signOut">
                  <i class="flaticon-logout"></i>
                  Logout
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div v-if="user&&user.role==='admin'">
    <LicenseDialog @close="licenseDialogFlag = false" @updated="updatedHandler" />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watchEffect } from "vue";
import { useAccountStore } from "@/stores";
import { storeToRefs } from "pinia";
import LightDarkSwtichBtn from "./LightDarkSwtichBtn.vue";
import AccountInfo from "@/components/Customer/AccountInfoForHeader.vue";
import AdminAccountInfo from "@/components/Admin/AccountInfoForHeader.vue";
import { Tooltip } from "bootstrap";
import LicenseDialog from "./LicenseDialog.vue"
import stateStore from "../../utils/store";
const stateStoreInstance = stateStore;
// Reactive state for sticky header
const isSticky = ref(false);
const licenseDialogFlag = ref(false)

const licenseView = () => {
  licenseDialogFlag.value = true
}

const updatedHandler = () => {
  licenseDialogFlag.value = false
}

// Access account store
const accountStore = useAccountStore();
const { user, notificationMessage } = storeToRefs(accountStore);

// Sign out function
const signOut = () => {
  accountStore.signout();
};

// onMounted lifecycle hook
onMounted(() => {
  // Listen for scroll to manage sticky header
  
  window.addEventListener('resize', setScrollSpeed); // Recalculate on window resize
  window.addEventListener("scroll", () => {
    let scrollPos = window.scrollY;
    isSticky.value = scrollPos >= 100;
  });

  // Initialize Bootstrap tooltips
  const tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  tooltipTriggerList.map((tooltipTriggerEl) => {
    return new Tooltip(tooltipTriggerEl);
  });
});
const scrollingText = ref(null);

watchEffect(() => {
  if(notificationMessage.value && scrollingText.value){
    setScrollSpeed();
  }
})

const setScrollSpeed = () => {
  if(notificationMessage.value){    
    const scrollingTextElement = scrollingText.value;
    const textWidth = scrollingTextElement.offsetWidth; // Get the width of the text
    const containerWidth = scrollingTextElement.parentElement.offsetWidth; // Get the width of the container

    // Set speed based on the width of the text
    const pixelsPerSecond = 50; // Speed constant (adjustable)
    const duration = (textWidth + containerWidth) / pixelsPerSecond;

    // Set the animation duration dynamically
    scrollingTextElement.style.animationDuration = `${duration}s`;
  }
};

// onMounted(() => {
//   setScrollSpeed();
//   window.addEventListener('resize', setScrollSpeed); // Recalculate on window resize
// });

onBeforeUnmount(() => {
  window.removeEventListener('resize', setScrollSpeed);
});
</script>

<style scoped>
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.notification-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #c4e32d;
  color: #000;
  padding: 10px;
  z-index: 9999;
  overflow: hidden;
}

.scrolling-text {
  display: inline-block;
  white-space: nowrap;
  animation: scroll-left linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>