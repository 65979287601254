
<template>
  <BreadCrumb PageTitle="Package" />
  <div class="row" v-if="user">
    <div class="col-xxl-12">
      <PackageContent />
    </div>
  </div>
</template>

<script setup>

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import PackageContent from "../../components/Admin/Package/PackageContent.vue";

import { useAccountStore } from "@/stores"
import { storeToRefs } from 'pinia';
const accountStore = useAccountStore();
const { user } = storeToRefs(accountStore);
</script>