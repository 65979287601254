import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '..';
import { updateStatistics } from './statistics.store';

const baseUrl = `${process.env.VUE_APP_VUE_API_URL}`;

export const useAdminPackageStore = defineStore('admin-package', {
    state: () => ({
        Packages:[],
        ISPNames:[],
        customers:[],
        PackageNameList:[],
        loading: false
    }),
    actions: {
        async loadPackages(serverName) {
            const alertStore = useAlertStore();
            try {
                this.loading  = true
                const res = await fetchWrapper.post(`${baseUrl}/admin/package/load`,{serverName});
                this.loading = false
                this.Packages = res.totalPackages
                updateStatistics()
            } catch (error) {
                alertStore.error(error);
            }
        },
        async getCustomers() {
            const alertStore = useAlertStore();
            try{
                const res = await fetchWrapper.get(`${baseUrl}/admin/package/get-customer-names`);
                this.customers = res.users
            } catch (error) {
                alertStore.error(error);
            }
        },
        async getPackageNameList(serverName) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/admin/package/get-names`,{serverName});
                this.PackageNameList = res.PackageNameList
            } catch (error) {
                alertStore.error(error);
            }
        },
        async loadISPNames(){
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.get(`${baseUrl}/admin/package/load-isp-names`,);
                this.ISPNames = res.ISPNames
            } catch (error) {
                alertStore.error(error);
            }
        },
        async createNewPackage( packageRequest) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/package/create`, packageRequest);
                updateStatistics()
                alertStore.success("New successfully added")

            } catch (error) {
                alertStore.error(error);
            }
        },
        async deletePackages(uuids) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/package/delete`, { uuids });
                updateStatistics()
                alertStore.success("Delete successful")

            } catch (error) {
                alertStore.error(error);
            }
        },
        async updatePackage(updateRuquest) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/package/update`, updateRuquest );
                updateStatistics()
                alertStore.success("Update successful")

            } catch (error) {
                alertStore.error(error);
            }
        },
        
    },
});