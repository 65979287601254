<template>
  <ResetPassword :resetid="resetid" :email="email"/>
</template>

<script setup>
import ResetPassword from "../../components/Authentication/ResetPassword/ResetPassword.vue";

import { useRoute } from 'vue-router';
const route = useRoute();
const resetid = route.query?.resetid || '';
const email = route.query?.email || '';
</script>