
<template>
  <BreadCrumb PageTitle="Coupon" />
  <div class="row" v-if="user">
    <div class="col-xxl-12">
      <CouponContent />
    </div>
  </div>
</template>

<script setup>

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import CouponContent from "../../components/Admin/Coupon/CouponContent.vue";

import { useAccountStore } from "@/stores"
import { storeToRefs } from 'pinia';
const accountStore = useAccountStore();
const { user } = storeToRefs(accountStore);
</script>