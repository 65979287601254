import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '..';
import { updateStatistics } from './statistics.store';

const baseUrl = `${process.env.VUE_APP_VUE_API_URL}`;

export const useAdminProxyStore = defineStore('admin-proxy', {
    state: () => ({
        xProxies: [],
        smartProxies: [],
        serverNames: [],
        loading: false
    }),
    actions: {
        async loadProxies(serverName) {
            const alertStore = useAlertStore();
            try {
                this.loading = true;
                const res = await fetchWrapper.post(`${baseUrl}/admin/proxy/load`, { serverName });
                this.xProxies = res.totalProxies.xProxyResult
                this.smartProxies = res.totalProxies.proxySmartResult
                updateStatistics()
                this.loading = false;
            } catch (error) {
                this.loading = false;
                alertStore.error(error);
            }
        },
        async loadServerNames() {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.get(`${baseUrl}/admin/proxy/load-server-names`,);
                this.serverNames = res.serverNames
            } catch (error) {
                alertStore.error(error);
            }
        },
        async loadPositionList(serverName) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/admin/proxy/load-position-list`, { serverName });
                return res.positionList
            } catch (error) {
                alertStore.error(error);
            }
        },
        async load_PS_AvailableModemList(serverName) {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/admin/proxy/load-proxysmart-modem-list`, { serverName });
                return {modemList: res.modemList, httpList: res.httpList, socketList: res.socketList}
            } catch (error) {
                alertStore.error(error);
            }
        },
        async createNewProxy(serverName, proxyRequest) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/proxy/create`, { serverName, proxyRequest });
                updateStatistics()
                alertStore.success("New successfully added")

            } catch (error) {
                alertStore.error(error);
            }
        },
        async updateProxy(uuid, modem_type, modem_id, proxyRequest) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/proxy/update`, { uuid, modem_type, modem_id, proxyRequest });
                if(modem_type === 'X_PROXY') {
                    this.xProxies = this.xProxies.map(xproxy => {
                        if(xproxy.uuid === uuid) {
                            xproxy = {
                                ...xproxy,
                                ...proxyRequest,
                                uploadLimitType: proxyRequest.uploadLimitType ? 'limited' : 'unlimited',
                                downloadLimitType: proxyRequest.downloadLimitType ? 'limited' : 'unlimited',
                                upDownLimitType: proxyRequest.upDownLimitType ? 'limited' : 'unlimited',
                            }
                        }
                        return xproxy;
                    })
                } else {
                    this.smartProxies = this.smartProxies.map(proxysmart => {
                        if(proxysmart.uuid === uuid) {
                            proxysmart = {
                                ...proxysmart,
                                ...proxyRequest
                            }
                        }
                        return proxysmart;
                    })
                }
                updateStatistics()
                alertStore.success("Proxy updated")

            } catch (error) {
                alertStore.error(error);
            }
        },
        async updateAction(uuid) {
            const alertStore = useAlertStore();
            try{
                const res = await fetchWrapper.post(`${baseUrl}/admin/proxy/update-active`, {uuid});
                if(res.item.modem_type === "X_PROXY") {
                    const temp = this.xProxies
                    this.xProxies = temp.map(xproxy => {
                        if(res.item.uuid === xproxy.uuid) {
                            xproxy.active = res.item.active
                        }
                        return xproxy
                    })
                } else {
                    const temp = this.smartProxies
                    this.smartProxies = this.smartProxies.map(proxysmart => {
                        if(res.item.uuid === proxysmart.uuid) proxysmart.active =  res.item.active
                        return proxysmart
                    })
                }
                if(res.item.active === 'inactive') {
                    alertStore.success("Proxy paused")
                } else {
                    alertStore.success("Proxy resumed")
                }
            } catch(error) {
                alertStore.error(error)
            }

        },
        async deleteProxy(uuids) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/proxy/delete`, { uuids });
                updateStatistics()
                alertStore.success("Delete successful")

            } catch (error) {
                alertStore.error(error);
            }
        },
    },
});