<template>
    <div class="w-full">
        <SearchBar @update-filters="updateFilters" @clear-filters="clearFilters" @add-click="openAddNewOneDialog"
            @update-advance-filters="updateAdvanceFiltersHandler">
        </SearchBar>
        <v-btn-toggle v-model="viewType" color="deep-purple-accent-3 " rounded="0" group class="border-b-none border"
            mandatory>
            <v-btn value="table" class="bg-white text-black">
                Table
            </v-btn>
            <v-btn value="graph" class="bg-white text-black ">
                Graph
            </v-btn>
        </v-btn-toggle>
        <TableView :serverList="serverList" v-if="viewType === 'table'" 
            class="border " />
        <GraphView :filters="filters" v-else-if="viewType === 'graph'" class="border bg-white text-black" />
    </div>
    <div>
        <AddNewOneDialog @close="addNewOneDialogFlag = false" />
    </div>
</template>
<script setup>
import { ref, watchEffect } from 'vue';
import { useAdminServerStore } from "@/stores"

import AddNewOneDialog from "./Modals/AddNewOneDialog.vue"

import { storeToRefs } from 'pinia';

import SearchBar from './SearchBar.vue';
// import AddNewOneDialog from "./Modals/AddNewOneDialog.vue"
// import UpdateInfoDialog from "./Modals/UpdateInfoDialog.vue"
const viewType = ref("table")
import GraphView from "./GraphView.vue"
import TableView from "./TableView.vue"
const filters = ref({
    searchFilterName: "",
    searchFilterLocation: "",
    searchFilterDomain: ""
})
const updateFilters = (filtername, value) => {
    filters.value[filtername] = value
    serverList.value = servers.value.filter(server => {
        const { searchFilterName, searchFilterLocation, searchFilterDomain } = filters.value;

        const nameFilters = searchFilterName.toLowerCase().split(' ');
        const locationFilters = searchFilterLocation.toLowerCase().split(' ');
        const domainFilters = searchFilterDomain.toLowerCase().split(' ');

        const nameMatches = nameFilters.every(filter => server.modemName.toLowerCase().includes(filter));
        const locationMatches = locationFilters.every(filter => server.country.toLowerCase().includes(filter) || server.city.toLowerCase().includes(filter));
        const domainMatches = domainFilters.every(filter => server.domain.toLowerCase().includes(filter));

        return nameMatches && locationMatches && domainMatches;
    });
};
const clearFilters = () => {
    // Clear the search filter values
    filters.value.searchFilterName = ""
    filters.value.searchFilterLocation = ""
    filters.value.searchFilterDomain = ""
    serverList.value = servers.value
};

const adminServerStore = useAdminServerStore()
const { servers } = storeToRefs(adminServerStore)
const serverList = ref(null)
adminServerStore.loadServers()
const addNewOneDialogFlag = ref(false)

const openAddNewOneDialog = () => {
    addNewOneDialogFlag.value = true
}

const updateAdvanceFiltersHandler = (filters) => {
    serverList.value = servers.value.filter(server =>
        server.domain.toLowerCase().includes(filters.domain.toLowerCase()) &&
        server.isp.toLowerCase().includes(filters.isp.toLowerCase()) &&
        (server.country.toLowerCase().includes(filters.location.toLowerCase()) ||
            server.city.toLowerCase().includes(filters.location.toLowerCase())
        ) &&
        server.modemName.toLowerCase().includes(filters.modemName.toLowerCase()) &&
        server.modem_type.toLowerCase() === (filters.modemType.toLowerCase())
    )
}
watchEffect(() => {
    serverList.value = servers.value
})


</script>