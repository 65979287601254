<template>
  
  <MyProxy />
</template>

<script setup>
import MyProxy from "@/components/Customer/MyProxy/MyProxy.vue";
import { useRoute } from 'vue-router';
import {useAlertStore} from '@/stores';
const alertStore = useAlertStore();
const route = useRoute();
// const router = useRouter();
const status = route.query?.status || '0';
if(status === '1'){
  alertStore.success("You have purchased successfully")
}
</script>