<template>
    <v-data-table :headers="headers" :items="serverList" item-key="name" class="border h-[500px] bg-white text-black min-h-[500px]"  items-per-page="-1"
    style="font-size: 13px;" :style="`height: ${computedHeight}px;`">
        <template #[`item.no`]="{ index }">
            {{ index + 1 }}
        </template>

        <template #[`item.modemName`]="{ item }">
            <div class="flex items-center">
                <v-icon icon="mdi-circle" size="15" :color="item.conn_status ? 'green' : 'red'" class="mr-2"></v-icon>
                {{ item.modemName }}
            </div>
        </template>
        <template #[`item.location`]="{ item }">
            {{ item.country + " " + item.city }}
        </template>
        <template #[`item.createdAt`]="{ item }">
            {{ item.createdAt.split('T')[0].replaceAll('-', '/') }}
        </template>
        <template v-slot:[`item.buttonBar`]="{ item }">
            <v-menu offset-y location="bottom">
                <template v-slot:activator="{ props, on, isActive }">
                    <v-btn v-bind="props" v-on="{ ...on }"  density="compact"
                        :style="isActive ? 'border-bottom-left-radius: 0px;border-bottom-right-radius: 0px;' : ''"
                        style="outline: none; font-size: 10px; " class="w-32 z-10 bg-dark text-light ">
                        Actions
                        <v-icon icon="mdi-chevron-down" size="14"></v-icon>
                    </v-btn>
                </template>
                <v-list class="w-32 flex-1 flex flex-col px-0 py-0  z-0"
                    style="border-top-left-radius: 0px;border-top-right-radius: 0px">
                    <v-btn v-for="(button, index) in actionButtons" :key="index" @click="clickAction(item, button)"
                        rounded="0" class="text-center bg-dark text-light"
                        style="font-size: 10px; width: 128px; outline: none; border-bottom: 1px solid #345;"
                        :data-bs-toggle="button === 'Update' && 'modal'"
                        :data-bs-target="button === 'Update' && '#serverUpdateModal'">
                        {{ button}}
                    </v-btn>
                </v-list>
            </v-menu>
        </template>
    </v-data-table>
    
    <div>
        <UpdateInfoDialog :item="selectedItem" @close="updateInfoDialogFlag=false" />
    </div>
</template>
<script setup>
import { ref, defineProps, watchEffect,  onMounted, onBeforeUnmount } from "vue"
import { useAdminServerStore, useAlertStore } from "@/stores"
import UpdateInfoDialog from "./Modals/UpdateInfoDialog.vue"


const adminServerStore = useAdminServerStore()
const headers = ref(
    [
        { title: 'No', align: 'center', key: 'no' },
        { title: 'Modem Name', align: 'center', key: 'modemName' },
        { title: 'Domain', align: 'center', key: 'domain' },
        { title: 'Location', align: 'center', key: 'location' },
        { title: 'Status', align: 'center', key: 'status' },
        { title: 'ISP', align: 'center', key: 'isp' },
        { title: 'Server Type', align: 'center', key: 'modem_type' },
        { title: 'Created Date', align: 'center', key: 'createdAt' },
        { title: 'Actions', align: 'center', key: 'buttonBar' },
    ]
);
const props = defineProps({
    serverList: {
        type: Object, // Assuming user object has a name property
        required: true
    },
});

const offsetHeight = 400; // Adjust this value as needed

const computedHeight = ref(window.innerHeight - offsetHeight);

const handleResize = () => {
  computedHeight.value = window.innerHeight - offsetHeight;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

const serverList = ref(props.serverList)
watchEffect(() => {
    serverList.value = props.serverList
}, [props.serverList])

const actionButtons = ref(
    ["Update", "Synchronize", "Pause/Resume", "Delete"]
)

const openUpdateInfoDialog = (item) => {
    selectedItem.value = item
    updateInfoDialogFlag.value = true
}
const clickAction = (item, button) => {
    switch (button) {
        case "Update":
            openUpdateInfoDialog(item)
            break
        case "Synchronize":
            adminServerStore.syncServer(item.uuid)
            break
        case "Pause/Resume":
            adminServerStore.pauseOrResume(item.uuid)
            break
        case "Delete":
            if(item.num !== 0) {
                const alertStore = useAlertStore()
                alertStore.warning("Can't delete used server")
                return
            }
            adminServerStore.deleteServer(item.uuid)

    }
}

const updateInfoDialogFlag = ref(false)
const selectedItem = ref(null)

</script>