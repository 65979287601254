<template>
  
  <RechargeContent />
</template>

<script setup>
import RechargeContent from "@/components/Customer/RechargeContent/RechargeContent.vue";
import { useRoute } from 'vue-router';
import {useAlertStore} from '@/stores';
const alertStore = useAlertStore();
const route = useRoute();
// const router = useRouter();
const status = route.query?.status || '0';
if(status === '-1'){
  alertStore.error("Insufficient balance")
}
</script>