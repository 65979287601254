<template>
    <div class="">
        <div class="flex justify-between items-center py-2 flex-wrap">
            <div class="flex flex-wrap gap-4 py-2">
                <SearchBar @server-selected="serverSelectedHandeler">
                </SearchBar>

                <div v-if="selectedServerName !== ''" class="w-56 pr-5 text-xs">
                    Usage Status
                    <v-select v-model="selectedSaleStatus" :items="saleStatusList" density="compact" variant="outlined"
                        rounded="0" placeholder="Select Sale Status" style="padding-top: 0px;padding-bottom: 0px;">
                    </v-select>
                </div>
                <div v-if="selectedServerName !== ''" class="w-56 pr-5 text-xs">
                    Proxy Connect Status
                    <v-select v-model="selectedConnStatus" :items="connectStatusList" density="compact" rounded="0"
                        variant="outlined" placeholder="Select Conn Status"
                        style="padding-top: 0px;padding-bottom: 0px;">
                    </v-select>
                </div>
            </div>
            <div v-if="selectedServerName !== ''">
                <v-btn icon="mdi-delete" size="small" style="outline: none;" @click="deleteProxies"
                    class="mr-5 bg-dark text-light"></v-btn>
                <v-btn v-if="selectedServerName !== 'All'" icon="mdi-plus" size="small" style="outline: none;"
                    data-bs-toggle="modal" 
                    :data-bs-target="selectedServerType === 'X_PROXY' ? '#xproxyAddModal' :
                                     selectedServerType === 'PROXYSMART' ? '#proxysmartAddModal' :
                                ''"
                    class="bg-dark text-light" @click="addProxy"></v-btn>
            </div>
        </div>
        <div v-if="loading === true" class=" flex items-center justify-center absolute custom-style " >
            <img src="../../../assets/loading.gif" class="w-[50px]"/>
        </div>
        <v-btn-toggle v-model="viewType" color="deep-purple-accent-3 " rounded="0" group class="border-b-none border"
            mandatory>
            <v-btn value="table" class="bg-white text-black">
                Table
            </v-btn>
            <v-btn value="graph" class="bg-white text-black ">
                Graph
            </v-btn>
        </v-btn-toggle>
        <TableView ref="tableViewRef" :selectedConnStatus="selectedConnStatus" :selectedServerName="selectedServerName"
            :selectedSaleStatus="selectedSaleStatus" :selectedServerType="selectedServerType"
            v-if="viewType === 'table'" class="border bg-white text-black" />
        <GraphView modem v-if="viewType === 'graph'" class="border bg-white text-black" />
    </div>
    <div>
        <XProxyAddNewOneDialog @close="addNewOneDialogFlag = false" :serverName="selectedServerName" />
        <ProxySmartAddNewOneDialog @close="addNewOneDialogFlag = false" :serverName="selectedServerName" />
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { useAdminProxyStore, useAlertStore } from "@/stores"
import { storeToRefs } from 'pinia';
import SearchBar from './SearchBar.vue';
import GraphView from "./GraphView.vue";
import TableView from "./TableView.vue";
import XProxyAddNewOneDialog from "./Modals/AddNewOneDialog-XProxy.vue"
import ProxySmartAddNewOneDialog from "./Modals/AddNewOneDialog-ProxySmart.vue"

import { watchEffect } from 'vue';
import { watch } from 'vue';
const viewType = ref("table")

const adminProxyStore = useAdminProxyStore()
const { xProxies, smartProxies, loading } = storeToRefs(adminProxyStore)
const addNewOneDialogFlag = ref(false)
const selectedServerName = ref("All")
const selectedServerType = ref("X_PROXY")


const serverSelectedHandeler = (server) => {
    if (server) {
        selectedServerName.value = server.name
        selectedServerType.value = server.type
        adminProxyStore.loadProxies(server.name)
    }
}
const addProxy = () => {
    addNewOneDialogFlag.value = true
}
const saleStatusList = ref(['All', 'AVAILABLE', 'USED'])
const connectStatusList = ref(['All', 'CONNECTED', 'DISCONNECTED'])
const selectedSaleStatus = ref('All')
const selectedConnStatus = ref('All')

const selectedItems = ref([])

const tableViewRef = ref(null)
const deleteProxies = () => {
    tableViewRef.value.deleteProxies()
}
</script>

<style scoped>
.custom-style {
    width: -webkit-fill-available; /* Safari/WebKit */
    height: -webkit-fill-available; /* Safari/WebKit */
    width: -moz-available; /* Firefox */
    height: -moz-available; /* Firefox */
    width: fill-available; /* Standard syntax, for future compatibility */
    height: fill-available; /* Standard syntax, for future compatibility */
}
</style>