<template>
  <div id="chart" class="text-[#333]">
    <div v-if="loading === true" class=" flex items-center h-[500px] justify-center" >
      <img src="../../../assets/loading.gif" class="w-[50px]"/>
    </div>
    <div v-else-if="loading === false">
      <apexchart v-if="chartOptions.xaxis.categories" type="bar" height="500" :options="chartOptions"
          :series="series"></apexchart>
      <v-data-table :headers="headers"  items-per-page="-1" id="tablecontainer"
        :items="Transactions" totalItems="100" hide-default-footer
        item-key="name" class="border h-[500px] bg-white text-black min-h-[500px]" 
        show-expand item-value="date" style="font-size: 13px;" :style="`height: ${computedHeight}px;`">

        <template v-slot:expanded-row="{ columns, item }">
          <td :colspan=" columns.length ">
            <div class="flex justify-center items-center">
              <div v-html=" item.description ">

              </div>

            </div>
          </td>


        </template>
        <template v-slot:[`item.no`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.date`]="{ item }">
          {{ formatDate(item.date) }}
        </template>
        <template #bottom></template>
      </v-data-table>
    </div>
  </div>
</template>
<script setup>
import { ref, watchEffect, defineProps, onMounted } from 'vue';
import { useAdminTransactionStore } from "@/stores"
import { storeToRefs } from 'pinia';
const adminTransactionStore = useAdminTransactionStore()

const { Transactions, loading } = storeToRefs(adminTransactionStore)
const series = ref(null);
let result = ref({});

const headers = ref(
    [
        { title: 'No', align: 'center', key: 'no' },
        { title: 'Customer', align: 'center', key: 'customer_name' },
        { title: 'Email', align: 'center', key: 'customer_email' },
        { title: 'Amount(USD)', align: 'center', key: 'amount' },
        { title: 'Type', align: 'center', key: 'type' },
        { title: 'Status', align: 'center', key: 'status' },
        { title: 'Pay from', align: 'center', key: 'pay_address' },
        { title: 'Pay_amount', align: 'center', key: 'pay_amount' },
        { title: 'Pay_currency', align: 'center', key: 'pay_currency' },
        { title: 'Date', align: 'center', key: 'date' },
    ]
);

const props = defineProps({
  serverName: {
    type: Object, // Assuming user object has a name property
    required: true
  },
  from: {
    type: Object, // Assuming user object has a name property
    required: true
  },
  to: {
    type: Object, // Assuming user object has a name property
    required: true
  },
  graphType: {
    type: Object,
    required: true
  }
});

onMounted(() => {
  const fromDate = props.from.toISOString().split('T')[0]
  const toDate = props.to.toISOString().split('T')[0]
  adminTransactionStore.loadTransactions({fromDate: fromDate, toDate: toDate, serverName: props.serverName});
});

const chartOptions = ref({
  chart: {
      type: 'bar',
      height: 500,
      stacked: true,
      toolbar: {
          show: false
      }
  },
  stroke: {
      width: 1,
      colors: ['#fff']
  },
  dataLabels: {
      enabled: true,
      formatter: (val) => {
          return `$${val}`
      }
  },
  plotOptions: {
      bar: {
          horizontal: false,
          columnWidth: '30px',
      }
  },
  xaxis: {
      categories: [],
      labels: {
          show: true,
          style: {
              fontFamily: "Red Hat Display, sans-serif",
              colors: "#9C9AB6",
              fontSize: "14px",
              fontWeight: 500,
          },
      },
  },
  fill: {
      opacity: 1  
  },
  colors: ['#4CAF50', '#FF9800', '#2196F3', '#FFD600'],
  yaxis: {
      labels: {
          style: {
              fontFamily: "Red Hat Display, sans-serif",
              colors: ["#9C9AB6"],
              fontSize: "14px",
              fontWeight: 500,
          },
          formatter: (val) => {
              return `$${val}`
          }
      }
  },
  legend: {
      position: 'top',
      horizontalAlign: 'left',
      labels: {
          colors: "#8E8DA1",
      },
  },
  tooltip: {
      fixed: {
          enabled: true,
          position: 'topRight',
          offsetX: 0,
          offsetY: 0,
      },
      labels: {
          colors: "#8E8DA1",
      },
  }
});
watchEffect(() => {
  const transactionsList = Transactions.value
  result.value = {}
  transactionsList.forEach(element => {
    if(element.type === 'PURCHASE' || element.type === 'EXTEND'){
      const date = new Date(element.date);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      
      if(props.graphType === "Daily"){
        const dailyKey = `${month}-${day}`;
        if(!result.value[dailyKey]) {
          result.value[dailyKey] = 0;
        }
        result.value[dailyKey] += Number(element.amount);
      }
      else if(props.graphType === "Weekly") {
        const week = getWeekNumber(date);
        const weeklyKey = `${year}-W${week}`;
        if (!result.value[weeklyKey]) {
          result.value[weeklyKey] = 0;
        }
        result.value[weeklyKey] += Number(element.amount);
      }
      else {
        const monthlyKey = `${year}-${month}`;
        if (!result.value[monthlyKey]) {
          result.value[monthlyKey] = 0;
        }
        result.value[monthlyKey] += Number(element.amount);
      }
    }
  });
  series.value = [
      {
          name: 'Income',
          group: 'proxy',
          data: Object.entries(result.value).map(item => item[1]).reverse()
      }
  ]
  chartOptions.value = {
    ...chartOptions.value,
    xaxis: {
      ...chartOptions.value.xaxis,
      categories: Object.entries(result.value).map(item => item[0]).reverse()
    }
  };
})

const getWeekNumber = (date) => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}
const formatDate = (date_m) => {
  const date = new Date(date_m);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${day}/${month}/${year}  ${hours}:${minutes}:${seconds}`;
}
</script>