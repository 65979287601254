<template>
  
  <MyReferral />
</template>

<script>
import { defineComponent } from "vue";
import MyReferral  from "@/components/Customer/MyReferral/ReferralContent.vue";

export default defineComponent({
  name: "MyReferralPage",
  components: {
    MyReferral,
  },
});
</script>