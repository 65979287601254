<template>
    <div class="modal fade" id="purchaseModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  w-[600px] ">
            <div class="modal-content" v-if="props.plan">
                <Form @submit="onUpdate" :validation-schema="schema" v-slot="{ errors }" class="">

                    <div class="modal-header">
                        <h1 class="modal-title fs-5">Purchases</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <div class="flex space-x-2">
                            <div class="text-sm w-1/2 pt-4 space-y-2">
                                <span @click="authType = 'username'" style="cursor: pointer;" class="flex">
                                    <div class="w-5 mr-4">
                                        <input class="form-check-input shadow-none" type="checkbox"
                                            :checked="authType === 'username'" />

                                    </div>
                                    <span class="text-sm">Username:Password</span>
                                </span>
                                <Field name="usernameVal" rules="required" v-slot="{ field }" v-model="newUserName">

                                    <div class="relative">
                                        <input v-bind="field" :disabled="authType !== 'username'"
                                            class=" form-control shadow-none rounded-0 text-black" />
                                        <i class="flaticon-refresh absolute right-0  mx-4 top-1/2 -translate-y-1/2 cursor-pointer"
                                            @click="generateRandomCredentials"></i>
                                    </div>
                                    <span class="text-danger">{{ errors.usernameVal }}</span>

                                </Field>
                                <span @click="authType = 'ip'" style="cursor: pointer;" class="flex">
                                    <div class="w-5 mr-4">
                                        <input class="form-check-input shadow-none" type="checkbox"
                                            :checked="authType === 'ip'" />
                                    </div>
                                    <span class="text-sm">IP Whitelist</span>
                                </span>
                                <Field name="ipAddressVal" rules="required" v-slot="{ field }">
                                    <input v-bind="field" :disabled="authType !== 'ip'" placeholder="1.1.1.1,2.2.2.2"
                                        class="form-control shadow-none rounded-0 text-black" />
                                    <span class="text-danger">{{ errors.ipAddressVal }}</span>
                                </Field>
                                <span class="flex">
                                    <span class="text-sm">Coupon Code</span>
                                </span>
                                <input v-model="couponCode"
                                        class="form-control shadow-none rounded-0 text-black" />
                            </div>
                            <div class="w-1/2 pt-4 space-y-2">
                                <span class="text-sm">Quantity</span>
                                <input type="number" v-model="portQuantity"
                                    class="form-control shadow-none rounded-0 text-black" />
                                <div class="text-xl justify-center h-20 flex items-center mx-auto space-x-4">
                                    <p>Total Amount: </p>
                                    <p class="text-3xl">${{
                    props.plan.price * portQuantity }}</p>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button> -->
                        <button type="submit" :disabled="buttonDisableFlag" class="btn btn-primary">Purchase</button>
                    </div>
                </Form>

            </div>
        </div>
    </div>
</template>
<script setup>
import { defineProps, ref, watchEffect } from "vue"
import { useRouter } from 'vue-router';
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import { useAccountStore, usePackageStore, useAlertStore } from "@/stores"

const accountStore = useAccountStore()
const packageStore = usePackageStore()
const props = defineProps({
    plan: {
        type: Object, // Assuming user object has a name property
        required: true
    },
    selectedDetails: {
        type: Object, // Assuming user object has a name property
        required: true
    },
    selectedServerType: {
        type: String, // Assuming user object has a name property
        required: true
    },
    openVPN: {
        type: Boolean,
        required: true
    }
    
});
const router = useRouter();

const portQuantity = ref(1)
const buttonDisableFlag = ref(false)
const authType = ref('username');
const newUserName = ref('');
const couponCode = ref('')
// const disabledAuthType = ref(null);
const schema = Yup.object().shape({
  usernameVal: Yup.string()
    .test('is-valid-username', 'Username:Password format is required (letters, numbers only)', function(value) {
        if(authType.value === 'username')  {
            return /^([a-zA-Z0-9]+):([a-zA-Z0-9]+)$/.test(value)
        } else {
            return true
        }
    })
    .when('authType', {
      is: 'username',
      then: Yup.string().required('Username:Password is required'),
    }),
  ipAddressVal: Yup.string()
    .test('is-valid-ip', 'IP address format is required', function (value) {
        if(authType.value === 'ip'){
        const ips = value?.split(',');
        return ips?.every(ip =>
          /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip.trim())
        );
    } else return true
    })
    .when('authType', {
      is: 'ip',
      then: Yup.string().required('IP address is required'),
    }),
});

const onUpdate = ((values) => {
    const { usernameVal, ipAddressVal } = values
    
    let auth_user = usernameVal
    let auth_ip = ipAddressVal
    switch (authType.value) {
        case "username":
            auth_ip = null
            break
        case "ip":
            auth_user = null
            break
    }
    if(authType.value === "username"){
        const userpass = usernameVal.split(':');
        if(userpass[0].length < 4 || userpass[1].length < 4) {
            const alertStore = useAlertStore()
            alertStore.error("Please lengthen username and password to 4 characters or more");
            return
        }
    }
    if ((auth_user || auth_ip) && props.plan.uuid && portQuantity.value >= 1 && portQuantity.value === Math.floor(portQuantity.value))
        // props.payWithBalance(props.plan.uuid, portQuantity.value, auth_user, auth_ip)
        if (props.plan.uuid && portQuantity.value > 0 && ((auth_user === null ^ auth_ip === null) === 1) && props.selectedDetails.city) {
            buttonDisableFlag.value = true
            accountStore.purchaseByBalanceFee(props.plan.uuid, portQuantity.value, auth_user, auth_ip, props.selectedDetails.city, props.openVPN, couponCode.value).then((res) => {
                buttonDisableFlag.value = false
                let modal = document.getElementsByClassName('modal-backdrop')[0];
                modal?.classList.remove('show'); // Remove the 'show' class to hide the modal
                modal?.setAttribute('aria-hidden', 'true'); // Set aria-hidden attribute to true
                modal?.setAttribute('style', 'display: none'); // Hide the modal
                let modal1 = document.getElementsByClassName('modal-backdrop')[1];
                modal1?.classList.remove('show'); // Remove the 'show' class to hide the modal
                modal1?.setAttribute('aria-hidden', 'true'); // Set aria-hidden attribute to true
                modal1?.setAttribute('style', 'display: none'); // Hide the modal
                packageStore.getCountryList(props.selectedServerType)
                if(res === 0){
                    router.push({path: '/recharge', query: {status: -1}});
                    return
                }
                else if(res === 1) {
                    router.push({path: '/my-proxy', query: {status: 1}});
                    return
                }
            })
        }
});

function generateRandomCredentials() {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let user_name = '';
    let password = '';
    for (let i = 0; i < 7; i++) {
        user_name += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    for (let i = 0; i < 7; i++) {
        password += Math.floor(Math.random() * 10); // Generates a random digit between 0 and 9
    }
    const newUsername = `u${user_name}:p${password}`
    // alert(newUsername)
    // handleSubmit({ usernameVal: newUsername });
    newUserName.value = newUsername;
}
generateRandomCredentials()
watchEffect(() => {
    portQuantity.value = Math.max(0, Math.floor(portQuantity.value))
}, [portQuantity.value])
</script>