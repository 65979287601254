import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
  components,
  directives,
  
  theme: {
    defaultTheme: "light",
    themes: {
      dark: {
        dark: true,
      },
      light: {
        dark: false,
      },
    },
  },
  display: {
    mobileBreakpoint: 'md',
    thresholds: {
      xs: 0,
      sm: 500,
      md: 1000,
      lg: 1200,
      xl: 1480,
    },
  },
});

export default vuetify;
