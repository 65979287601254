<template>
  <div class="user-list" :style="`height: ${computedHeight}px; background-color: ${isDarkMode ? '#2b2a3f' : '#F8F8FA'}`" id="userlist">
    <div class="w-full pr-5 text-xs flex items-center gap-x-3 flex-row justify-center py-3">
      <v-text-field placeholder="Search Email or User Name" variant="outlined" density="compact" v-model="searchName" rounded="0">
      </v-text-field>
    </div>
    <div
      v-for="user in (users.filter(u => [u.email, u.name].join(',').toLowerCase().includes(searchName.toLowerCase())))"
      :key="user.id"
      class="flex flex-row h-[55px] items-center justify-between p-2 listitem"
      @click="selectUser(user)"
      :class="{ selected: user.user_id === selectedUserId }"
    >
      {{ user.name }} ({{ user.email }}) 
      <span v-if="user.unreadCount" class=" z-10 text-[12px] w-[25px] min-w-[25px] badge rounded-pill flex bg-danger items-center justify-center aspect-square ">{{user.unreadCount}}</span>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, onMounted, onBeforeUnmount, watchEffect } from "vue";
import { storeToRefs } from "pinia";
import { useDarkModeStore } from "@/stores";
const darkStore = useDarkModeStore();
const {isDarkMode} = storeToRefs(darkStore);
const props = defineProps({
  users: Array
});
const searchName = ref("")
const emit = defineEmits(['selectUser']);
const selectedUserId = ref(null);
const offsetHeight = 250; // Adjust this value as needed

const computedHeight = ref(window.innerHeight - offsetHeight);


const handleResize = () => {
  computedHeight.value = window.innerHeight - offsetHeight;
};
onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
const selectUser = (user) => {
  selectedUserId.value = user.user_id;
  emit('selectUser', user);
};
</script>

<style scoped>
.user-list {
  width: 300px;
  border-right: 1px solid #ccc;
  overflow-y: auto;
  
}

.user-list .listitem {
  cursor: pointer;
  padding: 5px 0;
}

.user-list .listitem:hover {
  background: #b4b4b4;
}

.user-list .selected {
  background: #cfcfcf; /* Or any color you prefer for the selected background */
  color: #fff; /* Optional: Change text color for better contrast */
}


@media only screen and (max-width: 500px) {
  .user-list {
    position: absolute;
    left: -300px; /* Hidden initially off-screen */
    transition: left 0.3s ease;
    z-index: 100;
  }
  .uservisible {
    left: 0; /* Slide in from left to right */
  }
  .userhidden {
    left: -300px; /* Slide out to right to left */
  }  
}
</style>
