
<template>
  <BreadCrumb PageTitle="Site Information" />
  <div class="row" v-if="user">
    <div class="col-xxl-12">
      <MetaContent />
    </div>
  </div>
</template>

<script setup>

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import MetaContent from "../../components/Admin/Meta/MetaContent.vue";

import { useAccountStore } from "@/stores"
import { storeToRefs } from 'pinia';
const accountStore = useAccountStore();
const { user } = storeToRefs(accountStore);
</script>