<template>
    <div></div>
</template>

<script setup>
import { watch } from "vue";
import { storeToRefs } from 'pinia';

import { useAlertStore } from '@/stores';
import { toast } from "vue3-toastify";

const alertStore = useAlertStore();
const { alert } = storeToRefs(alertStore);

watch(alert, (newVal) => {
    if (newVal) {
        toast(newVal.message, {
            type: newVal.type,
            autoClose: 2000,
            theme: "colored",
            transition: toast.TRANSITIONS.FLIP,
            position: toast.POSITION.TOP_RIGHT,
            toastStyle: {
                fontSize: '14px',
            },
        });
    }
});
</script>
