<template>
    <div class="modal fade" id="licenseAddModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  w-[700px] ">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Add New License</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body ">
                    <div class="">
                        <div class="float-left w-1/2 px-2 py-2 h-20 modal-item">
                            Customer Email:
                            <v-combobox v-model="customerName" :items="customerList?.map(item => item.email)" 
                                rounded="0" variant="outlined" style="outline: none;" density="compact" :hide-no-data="false">
                                <template v-slot:no-data>
                                    <v-list-item density>
                                        <v-list-item-title density="compact" variant="disabled">
                                            <p class="text-sm text-gray-600 text-center">Not found</p>
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-combobox>
                        </div>
                        <div class="float-left w-1/2 px-2 py-2 h-20 modal-item">
                            Customer Name:
                            <v-text-field v-model="customerEmail" variant="outlined" density="compact" rounded="0"
                                readonly></v-text-field>
                        </div>
                        <div class="float-left w-1/2 px-2 py-2 h-20 modal-item">
                            Modem Name:
                            <v-text-field v-model="selectedModemName" variant="outlined" rounded="0"
                                density="compact"></v-text-field>
                        </div>
                        <div class="float-left w-1/2 px-2 py-2 h-20 modal-item">
                            Status:
                            <v-select v-model="status" :items="statusOptions" variant="outlined" rounded="0"
                                density="compact"></v-select>
                        </div>
                        <div class="float-left w-1/2 px-2 py-2 h-20 modal-item">
                            Proxies:
                            <v-select v-model="selectedProxy"
                                :items="proxyList?.map(proxy => `${proxy.shared_port}/${proxy.brother_port}`)"
                                rounded="0" variant="outlined" density="compact"></v-select>
                        </div>
                        <div class="float-left w-1/2 px-2 py-2 h-20">
                            Package Name:
                            <v-select v-model="selectedPackage" :items="packageList?.map(pkg => pkg.name)" rounded="0"
                                variant="outlined" density="compact"></v-select>
                        </div>
                        <div class="float-left w-1/2 px-2 py-2 h-20 modal-item">
                            <span @click="authType = 'username'" style="cursor: pointer;" class="flex">
                                <div class="w-5 mr-4">
                                    <input :checked="authType === 'username'" class="form-check-input shadow-none"
                                        type="checkbox" />
                                </div>
                                <span class="text-sm">Authentication User:</span>
                            </span>

                            <v-text-field v-model="auth_user" variant="outlined" density="compact" rounded="0"
                                :disabled="authType !== 'username'">
                                <template v-slot:append-inner>
                                    <v-icon icon="mdi-refresh" @click="generateRandomCredentials"></v-icon>
                                </template>
                            </v-text-field>
                        </div>
                        <div class="float-left w-1/2 px-2 py-2 h-20 modal-item">
                            <span @click="authType = 'ip'" style="cursor: pointer;" class="flex">
                                <div class="w-5 mr-4">
                                    <input :checked="authType === 'ip'" class="form-check-input shadow-none"
                                        type="checkbox" />
                                </div>
                                <span class="text-sm">Authentication IPs:</span>
                            </span>

                            <v-text-field v-model="auth_ips" variant="outlined" density="compact" rounded="0"
                                :disabled="authType !== 'ip'"></v-text-field>
                        </div>
                        <div class="float-left w-1/2 px-2 py-2 h-20 modal-item">
                            Start Date:
                            <VueDatePicker v-model="startDate" format='dd/MM/yyyy HH:mm' ></VueDatePicker>
                        </div>
                        <div class="float-left w-1/2 px-2 py-2 h-20 modal-item">
                            Expiration Date:
                            <VueDatePicker v-model="expiredDate" format='dd/MM/yyyy HH:mm'></VueDatePicker>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" :disabled="buttonDisableFlag" class="btn btn-primary"
                        @click="createLicense">Create</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watchEffect, defineProps, defineEmits } from "vue";

import {  useAdminLicenseStore, useAlertStore } from "@/stores"
import VueDatePicker from '@vuepic/vue-datepicker';

// import { watch } from "vue";
// import { enGB } from 'date-fns/locale';
// const adminPackageStore = useAdminPackageStore()
const adminLicenseStore = useAdminLicenseStore()

const props = defineProps({
    selectedServerName: {
        type: String, // Assuming user object has a name property
        required: true
    },
});
const statusOptions = ref([
    'ACTIVE',
    'EXPIRED'
]);

const customerName = ref("");
const customerEmail = ref("");
const startDate = ref(new Date());
const expiredDate = ref(new Date());
const status = ref("Active");
const auth_user = ref("");
const auth_ips = ref("");
const emit = defineEmits(['inInput', 'submit'])
const buttonDisableFlag = ref(false)
const createLicense = () => {
    // Call your function to add the values
    // For example:
    let temp_auth_user = auth_user.value
    let temp_auth_ips = auth_ips.value
    switch (authType.value) {
        case "username":
            temp_auth_ips = null
            break
        case "ip":
            temp_auth_user = null
            break
    }
    if(authType.value === "username"){
        const userpass = temp_auth_user.split(':');
        if(userpass[0].length < 4 || userpass[1].length < 4) {
            const alertStore = useAlertStore()
            alertStore.error("Please lengthen username and password to 4 characters or more");
            return
        }
    }
    const createRequest = {
        customer_name: customerEmail.value,
        customer_email: customerName.value,
        start_date: new Date(startDate.value).toISOString(),
        expired_date: new Date(expiredDate.value).toISOString(),
        modem_name: selectedModemName.value,
        package_name: selectedPackage.value,
        ports: selectedProxy.value,
        status: status.value,
        auth_user: temp_auth_user,
        auth_ips: temp_auth_ips,
    };
    buttonDisableFlag.value = true
    adminLicenseStore.createLicense(createRequest).then(() => {
        buttonDisableFlag.value = false
        emit('updated')
    })

};

const selectedModemName = ref("")
const selectedProxy = ref("")
const selectedPackage = ref("")

const customerList = ref([])
const proxyList = ref([])
const packageList = ref([])


watchEffect(async () => {
    // modemList.value = await adminLicenseStore.getModemList(props.selectedServerName)
    selectedModemName.value = props.selectedServerName
    customerList.value = await adminLicenseStore.getClientList()
    customerName.value = customerList.value[0].email
}, [props.selectedServerName])

watchEffect(async () => {
    if (customerName.value) {
        const selectedCustomer = customerList.value.find(item => item.email === customerName.value)
        if (selectedCustomer) {
            customerEmail.value = selectedCustomer.user_name
        }

    }
}, [customerName.value])

watchEffect(async () => {
    if (selectedModemName.value !== "") {
        const { proList, pkgList } = await adminLicenseStore.getProxiesAndPackages(selectedModemName.value)
        proxyList.value = proList
        if (proList?.length > 0) {
            selectedProxy.value = `${proxyList.value[0].shared_port}/${proxyList.value[0].brother_port}`
        }
        else {
            selectedProxy.value = null
        }
        packageList.value = pkgList
        if (pkgList.length > 0) {
            selectedPackage.value = packageList.value[0].name
        }
        else {
            selectedPackage.value = null
        }
    }
}, [selectedModemName.value])

watchEffect(() => {
    if (selectedPackage.value ) {

        const time_unit = packageList.value.find(item => item.name === selectedPackage.value)?.time_unit;
        let newDate = new Date();

        if (time_unit === 'DAY') {
            newDate.setDate(newDate.getDate() + 1);
        } else if (time_unit === 'WEEK') {
            newDate.setDate(newDate.getDate() + 7);
        } else if (time_unit === 'MONTH') {
            const monthsToAdd = parseInt(selectedPackage.value, 10);
            newDate.setMonth(newDate.getMonth() + monthsToAdd);
        }
        expiredDate.value = (newDate)
    }
}, [selectedPackage.value]);
function generateRandomCredentials() {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let username = '';
    let password = '';
    for (let i = 0; i < 7; i++) {
        username += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    for (let i = 0; i < 7; i++) {
        password += Math.floor(Math.random() * 10); // Generates a random digit between 0 and 9
    }
    auth_user.value = `u${username}:p${password}`;
}


const authType = ref('username');

generateRandomCredentials();

</script>