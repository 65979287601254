<template>
    <div class="modal fade" id="extendLicenseModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered w-[400px]">
            <div class="modal-content" v-if="props.license">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Extend</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="flex items-center justify-between">
                        <input class="form-check-input shadow-none" type="checkbox" id="month" value="MONTH" @click="extendType = 'MONTH'" :checked="extendType === 'MONTH'"/>
                        <label class="text-sm" for="month">Month</label>
                        <input class="form-check-input shadow-none" type="checkbox" id="week" value="WEEK" @click="extendType = 'WEEK'" :checked="extendType === 'WEEK'"/>
                        <label class="text-sm" for="week">Week</label>
                        <input class="form-check-input shadow-none" type="checkbox" id="day" value="DAY" @click="extendType = 'DAY'" :checked="extendType === 'DAY'"/>
                        <label class="text-sm" for="day">Day</label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button @click="onExtend" :disabled="buttonDisableFlag" class="btn btn-primary">Extend</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import { useProxyStore } from "@/stores"

const extendType = ref('MONTH');
const buttonDisableFlag = ref(false)
const proxyStore = useProxyStore();

const props = defineProps({
    license: {
        type: Object,
        required: true
    }
});
const emit = defineEmits(['inInput', 'submit'])

const onExtend = () => {
    buttonDisableFlag.value = true;
    proxyStore.extendLicense(props.license.uuid, extendType.value).then(() => {
        buttonDisableFlag.value = false;
        emit('close');
    });
};
</script>
