<template>
  <div class="flex justify-between flex-wrap items-center">
    <div class="flex justify-start flex-wrap items-center py-2">
      <div class="w-56 pr-5 text-xs">
        Name
        <input v-model="searchName" type="text" class="form-control shadow-none rounded-0 text-black" placeholder="Name"
          @input="emitFilters('searchFilterName', searchName)">
      </div>
      <div class="w-56 pr-5 text-xs">
        Location
        <input v-model="searchLocation" type="text" class="form-control shadow-none rounded-0 text-black"
          placeholder="Location" @input="emitFilters('searchFilterLocation', searchLocation)">
      </div>
      <div class="w-56 pr-5 text-xs">
        Domain
        <input v-model="searchDomain" type="text" class="form-control shadow-none rounded-0 text-black"
          placeholder="Domain" @input="emitFilters('searchFilterDomain', searchDomain)">
      </div>
      <div class="pr-5 cursor-pointer text-sm" @click="clearFilter(); $emit('clearFilters')">
        Clear
        <v-icon icon="mdi-refresh"></v-icon>
      </div>
    </div>
    <div>
      <v-btn icon="mdi-plus" type="button" size="small" style="outline: none;" class="bg-dark text-light" data-bs-toggle="modal"
        data-bs-target="#serverAddModal"></v-btn>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';


const searchName = ref("");
const searchLocation = ref("");
const searchDomain = ref("");
const advanceSearchFlag = ref(false)

const emit = defineEmits(['inInput', 'submit'])
const emitFilters = (filtername, value) => {
  emit('updateFilters', filtername, value);
};
const clearFilter = () => {
  searchName.value = ""
  searchLocation.value = ""
  searchDomain.value = ""
}
const advanceSearch = () => {
  advanceSearchFlag.value = true
  // Implement your advance search functionality here
};
const updateAdvanceFiltersHandler = (filters) => {
  emit('updateAdvanceFilters', filters)
}
</script>