<template>
  <div class="flex justify-between items-center py-2">
    <div class="flex justify-start items-center">
      <div class="w-56 pr-5 text-xs">
        Name
        <v-select v-model="selectedServerType" :items="serverNames" density="compact" item-title="name" rounded="0"
          variant="outlined" placeholder="Select Server Name" style="padding-top: 0px;padding-bottom: 0px;">
          <template v-slot:selection="{ item }"  >
            <v-list-item :title="item.raw.name" density style="padding: 0;">
              <template v-slot:prepend>
                <v-icon  v-if="item.raw.name!=='All'" :color="item.raw.conn_status ? 'green' : 'red'" size="10" density="compact">mdi-circle</v-icon>
              </template>
            </v-list-item>
          </template>
          <template v-slot:item="{ props, item }">
            <v-list-item class="w-[250px]" v-bind="props" :title="item.raw.name"   density="compact">
              <template v-slot:prepend>
                <v-icon  v-if="item.raw.name!=='All'" :color="item.raw.conn_status ? 'green' : 'red'" size="10">mdi-circle</v-icon>
              </template>
            </v-list-item>
          </template>
        </v-select>
      </div>
      
    </div>
    
  </div>
  <!-- <div>
    <v-dialog v-model="advanceSearchFlag" max-width="700">
      <AdvanceSearchDialog @close="advanceSearchFlag = false" @update-advance-filters="updateAdvanceFiltersHandler" />
    </v-dialog>
  </div> -->
</template>

<script setup>
import { ref, watchEffect, defineProps, defineEmits } from 'vue';

// import AdvanceSearchDialog from "./Modals/AdvanceSearchDialog.vue"
import {useAdminProxyStore } from "@/stores"
import { storeToRefs } from 'pinia';

const emit = defineEmits(['inInput', 'submit'])

const selectedServerType = ref("All")

const adminProxyStore = useAdminProxyStore()
adminProxyStore.loadServerNames()
const {serverNames} = storeToRefs(adminProxyStore)

watchEffect(()=>{
  emit('nameSelected', selectedServerType.value)
},[selectedServerType.value])
</script>