<template>
    <div id="chart" class="text-[#333] ">
        <apexchart v-if="chartOptions.xaxis.categories" type="bar" height="500" :options="chartOptions"
            :series="series"></apexchart>
    </div>
</template>
<script setup>
import { ref, watchEffect } from 'vue';
import { useAdminProxyStore } from "@/stores"
import { storeToRefs } from 'pinia';
const adminProxyStore = useAdminProxyStore()
const { xProxies } = storeToRefs(adminProxyStore)
const bytes = ref([])
const series = ref(null);

const chartOptions = ref({
    chart: {
        type: 'bar',
        height: 250,
        stacked: true,
        toolbar: {
            show: false
        }
    },
    stroke: {
        width: 1,
        colors: ['#fff']
    },
    dataLabels: {
        formatter: () => {
            return ''
        }
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '30px',
        }
    },
    xaxis: {
        categories: [],
        labels: {
            show: true,
            style: {
                fontFamily: "Red Hat Display, sans-serif",
                colors: "#9C9AB6",
                fontSize: "14px",
                fontWeight: 500,
            },
        },
    },
    fill: {
        opacity: 1  
    },
    colors: ['#4CAF50', '#FF9800', '#2196F3', '#FFD600'],
    yaxis: {
        labels: {
            style: {
                fontFamily: "Red Hat Display, sans-serif",
                colors: ["#9C9AB6"],
                fontSize: "14px",
                fontWeight: 500,
            },
            formatter: (val) => {
                return val?.toFixed(2) + "MB"
            }
        }
    },
    legend: {
        position: 'top',
        horizontalAlign: 'left',
        labels: {
            colors: "#8E8DA1",
        },
    },
    tooltip: {
        fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
        },
        labels: {
            colors: "#8E8DA1",
        },
    }
});
watchEffect(() => {
    const httpProxies = xProxies.value
    bytes.value = httpProxies.map(proxy => {
        return {
            http_port: proxy.http_port,
            http_ul_bytes: parseFloat(proxy.http_ul_bytes),
            http_dl_bytes: parseFloat(proxy.http_dl_bytes),
            sock_port: proxy.sock_port,
            sock_ul_bytes: parseFloat(proxy.sock_ul_bytes),
            sock_dl_bytes: parseFloat(proxy.sock_dl_bytes)
        }
    })

    series.value = [
        {
            name: 'HTTP Ul Bytes',
            group: 'http',
            data: bytes.value.map(byte => byte.http_ul_bytes)
        },
        {
            name: 'Sock Ul Bytes',
            group: 'sock',
            data: bytes.value.map(byte => byte.sock_ul_bytes)
        },
        {
            name: 'HTTP Dl Bytes',
            group: 'http',
            data: bytes.value.map(byte => byte.http_dl_bytes)
        },
        {
            name: 'Sock Dl Bytes',
            group: 'sock',
            data: bytes.value.map(byte => byte.sock_dl_bytes)
        },

    ]
    if (bytes.value.length > 0)
        chartOptions.value.xaxis.categories = bytes.value.map(byte => byte.http_port + "/" + byte.sock_port)
    // bytes.value.map(byte => { const portStr = byte.http_port + "/" + bytes.sock_port;  return portStr })
}, [xProxies.value])


</script>