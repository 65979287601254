<template>
  <div class="modal fade" id="osSpoofingModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered w-[400px]">
          <div class="modal-content" v-if="props.license">
              <div class="modal-header">
                  <h1 class="modal-title fs-5">OS Spoofing</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="float-left w-full px-2 py-2">
                  <v-select v-model="selectedOSType" :items="types" density="compact" item-title="name" rounded="0"
                  variant="outlined" style="padding-top: 0px;padding-bottom: 0px;">
                      <template v-slot:selection="{ item }"  >
                          <v-list-item :title="item.raw.name" density style="padding: 0;">
                          <template v-slot:prepend>
                              {{item.name}}
                          </template>
                          </v-list-item>
                      </template>
                      <template v-slot:item="{ props, item }">
                          <v-list-item v-bind="props" :title="item.raw.name"   density="compact">
                          <template v-slot:prepend>
                              {{ item.name }}
                          </template>
                          </v-list-item>
                      </template>
                  </v-select>
              </div>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                  <button @click="onUpdate" :disabled="buttonDisableFlag" class="btn btn-primary">Update</button>
              </div>
          </div>
      </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watchEffect } from 'vue';
import { useProxyStore } from "@/stores"

const buttonDisableFlag = ref(false)
const proxyStore = useProxyStore();

const props = defineProps({
  license: {
      type: Object,
      required: true
  }
});
const emit = defineEmits(['inInput', 'submit'])

const types = ref([
    {value: '', name: '--No Spoofing--'}, 
    {value: 'android:1', name: 'android:1, p0f compliand but slow'},
    {value: 'android:3', name: 'android:3, real Android, almost like Linux'},
    {value: 'android:4', name: 'android:4, real Android 14'},
    {value: 'macosx:3', name: 'macosx:3'},
    {value: 'macosx:4', name: 'macosx:4, real MacOSX 12.6 / iPhone 13 Pro Max'},
    {value: 'macosx:5', name: 'macosx:5, real MacOSX Ventura 13.5.1'},
    {value: 'ios:1', name: 'ios:1, p0f compliant'},
    {value: 'ios:2', name: 'ios:2, real lphone'},
    {value: 'ios:3', name: 'ios:3, real IPhone 12 max pro'},
    {value: 'windows:1', name: 'windows:1, real Windnows 10 64bit Server or Tiny'},
    {value: 'windows:4', name: 'windows:4, real Windows 10 and 11 64bit Desktop'}
])
const selectedOSType = ref({value: '', name: '--No Spoofing--'})
watchEffect(() => {
  selectedOSType.value = props?.license ? types.value.find(t => t.value === props?.license.os) || { value: '', name: '--No Spoofing--' } : '';
})
const onUpdate = () => {
  buttonDisableFlag.value = true;
  proxyStore.changeOS(props.license.uuid, selectedOSType.value).then(() => {
      buttonDisableFlag.value = false;
      emit('close');
  });
};
</script>
