
<template>
  <BreadCrumb PageTitle="Customer" />
  <div class="row" v-if="user">
    <div class="col-xxl-12">
      <CustomerContent />
    </div>
  </div>
</template>

<script setup>
// import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import CustomerContent from "../../components/Admin/Customer/CustomerContent.vue";

import { useAccountStore } from "@/stores"
import { storeToRefs } from 'pinia';
const accountStore = useAccountStore();
const { user } = storeToRefs(accountStore);
</script>