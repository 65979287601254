import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '..';
// import { updateLicenses } from '../account.store';

const baseUrl = `${process.env.VUE_APP_VUE_API_URL}`;

export const useAdminConfigurationStore = defineStore('admin-configuration', {
    state: () => ({
        countryList:[],
        ispList: [],
        openvpn: null
    }),
    actions: {
        async getOpenvpn(){
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.get(`${baseUrl}/admin/configuration/get-openvpn`, );
                this.openvpn = res.openvpn
            } catch (error) {
                alertStore.error(error);
            }
        },

        async getCountryIspList() {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.get(`${baseUrl}/admin/configuration/get-country-isp-list`, );
                this.countryList = res.countryList
                this.ispList = res.ispList
            } catch (error) {
                alertStore.error(error);
            }
        },
        async setOpenvpn(data) {
            const alertStore = useAlertStore();
            try{
                await fetchWrapper.post(`${baseUrl}/admin/configuration/set-openvpn`, {data: data});
            } catch (error) {
                alertStore.error(error)
            }
        },
        async setCountrySellable(countryName, sellableFlag) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/configuration/set-country-sellable`, {countryName, sellableFlag});
            } catch (error) {
                alertStore.error(error);
            }
        },
        async setAllCountryOrder(countryList) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/configuration/set-country-orderAll`, {countryList});
            } catch (error) {
                alertStore.error(error);
            }
        },
        async setCountryOrder(name, order) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/configuration/set-country-order`, {name, order});
                // await this.getCountryIspList();
            } catch (error) {
                alertStore.error(error);
            }
        },
        async setIspSellable(isp_id, sellableFlag) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/configuration/set-isp-sellable`, {isp_id, sellableFlag});
            } catch (error) {
                alertStore.error(error);
            }
        },
        async changeIspImage(formData) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/configuration/set-isp-image`, formData);
            } catch (error) {
                alertStore.error(error);
            }
        },
        
    },
});